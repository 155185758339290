<template>
  <header class="bg-default index-1000 relative w-full min-md:drop-shadow">
    <div
      class="flex items-center justify-between common-header "
      style="
        padding: 0 clamp(0.938rem, 0.607rem + 1.41vw, 1.875rem);
        font-family: 'San Francisco Display';
      "
    >
      <a href="/"><HeaderLogo style="width: 4.0055rem; height: 1.875rem" /></a>
      <div
        v-if="showNavigators"
        class="w-250 flex items-center justify-between font-medium max-md:hidden"
      >
        <a v-for="navigator in navigatorList" :key="navigator.name" :href="navigator.link">
          {{ navigator.name }}
        </a>
      </div>
      <div class="flex items-center">
        <slot name="button"></slot>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          class="min-lg:hidden ml-5"
          @click="drawerVisible = !drawerVisible"
        >
          <path
            d="M25.4295 13.4662H4.5704C3.7524 13.4662 3.03662 14.1821 3.03662 15C3.03662 15.8179 3.75246 16.5338 4.5704 16.5338H25.4295C26.2475 16.5338 26.9633 15.8179 26.9633 15C26.9633 14.1821 26.2474 13.4662 25.4295 13.4662Z"
            fill="#1A4D2E"
          />
          <path
            d="M25.4295 22.362H4.5704C3.7524 22.362 3.03662 23.0779 3.03662 23.8958C3.03662 24.7138 3.75246 25.4296 4.5704 25.4296H25.4295C26.2475 25.4296 26.9633 24.7138 26.9633 23.8958C26.9633 23.0779 26.2474 22.362 25.4295 22.362Z"
            fill="#1A4D2E"
          />
          <path
            d="M4.5704 7.63796H25.4295C26.2475 7.63796 26.9633 6.92212 26.9633 6.10418C26.9633 5.28624 26.2474 4.5704 25.4295 4.5704H4.5704C3.7524 4.5704 3.03662 5.28624 3.03662 6.10418C3.03662 6.92212 3.75246 7.63796 4.5704 7.63796Z"
            fill="#1A4D2E"
          />
        </svg>
      </div>
    </div>

    <SideDrawer
      v-model="drawerVisible"
      ref="drawer"
      class="drawer-container"
      height="calc(100vh - clamp(3.75rem, 3.53rem + 0.94vw, 4.375rem))"
      top-offset="clamp(3.75rem, 3.53rem + 0.94vw, 4.375rem)"
    >
      <div class="bg-white pb-10 w-full pt-4">
        <nav class="flex flex-col px-12 pb-16 w-full">
          <template v-for="navigator in navigatorList" :key="navigator.name">
            <a :href="navigator.link" style="width: min-content">
              <button
                :name="navigator.name"
                class="mobile-more-button text-lg font-semibold px-10 py-4 hover:opacity-80 text-black border-black rounded-full border-[1px]"
              >
                {{ navigator.name }}
              </button>
            </a>
            <hr class="my-4 h-[2px] w-[130%] -translate-x-[10%] bg-black opacity-10" />
          </template>
          <a href="#sku">
            <button
              name="Get Started"
              class="mobile-more-button button-gradient text-white text-lg font-semibold px-10 py-4 hover:opacity-80 border-white rounded-full border-[1px]"
              @click="handleClick"
            >
              Get Started
            </button>
          </a>
        </nav>
      </div>
    </SideDrawer>
  </header>
</template>

<script lang="ts" setup>
import { HeaderLogo } from '@/assets/svg'
import { ref } from 'vue'
import SideDrawer from '../common/side-drawer.vue'
import { toSku } from '@/utils';

withDefaults(
  defineProps<{
    showNavigators?: boolean
  }>(),
  {
    showNavigators: false,
  },
)

const navigatorList = ref([
  { name: 'FAQ', link: '/faq' },
  { name: 'Guide', link: '/guides' },
  { name: 'Contact Us', link: '/contact' },
])

const drawerVisible = ref(false)

const handleClick = () => {
  drawerVisible.value = false
  toSku()
}
</script>

<style scoped>
.common-header {
  height: clamp(3.75rem, 3.53rem + 0.94vw, 4.375rem);
  /* remove a little white line */
  margin-bottom: -0.8px;
}
.mobile-more-button {
  min-width: 10.75rem;
  min-height: 3.875rem;
}
</style>
