// src/fb-pixel.ts

// 定义 Facebook Pixel 的类型：`fbq` 函数
interface Fbq {
  (command: 'init', pixelId: string): void // 初始化 Pixel
  (command: 'track', eventName: string, parameters?: Record<string, any>): void // 追踪标准事件
  (command: 'trackCustom', eventName: string, parameters?: Record<string, any>): void // 追踪自定义事件
}

// 在全局 Window 对象上扩展 fbq
declare global {
  interface Window {
    fbq?: Fbq // 表示 fbq 为可选字段
  }
}

// 初始化 Facebook Pixel
export function initFacebookPixel(pixelId: string): void {
  // 如果 fbq 没有初始化，则动态加载 Facebook Pixel 脚本
  if (!window.fbq) {
    ;(function (f: any, b: any, e: any, v: any, n?: any, t?: any, s?: any) {
      if (f.fbq) return
      n = f.fbq = function (...args: any[]) {
        ;(f.fbq as any).callMethod
          ? (f.fbq as any).callMethod(...args)
          : (f.fbq as any).queue.push(args)
      }
      if (!f._fbq) f._fbq = n
      n.push = n
      n.loaded = true
      n.version = '2.0'
      n.queue = []
      t = b.createElement(e)
      t.async = true
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
  }

  // 初始化 Facebook Pixel 的 ID
  window.fbq?.('init', pixelId) // 调用 fbq 的初始化方法
  window.fbq?.('track', 'PageView') // 默认追踪页面访问
}

// 用于触发 Facebook Pixel 事件
export function trackEvent(eventName: string, parameters?: Record<string, any>): void {
  if (window.fbq) {
    window.fbq('track', eventName, parameters) // 调用 fbq 的事件追踪方法
  } else {
    console.warn('[Facebook Pixel] fbq is not initialized.')
  }
}
