import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/views/home-page.vue'

const router = createRouter({
  scrollBehavior() {
    return { top: 0 }
  },
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomePage,
    },
    {
      path: '/home',
      redirect: '/',
    },
    {
      path: '/index',
      redirect: '/',
    },
    {
      path: '/404',
      name: 'not-found',
      component: () => import('@/views/not-found-page.vue'),
    },
    {
      path: '/product',
      name: 'product',
      children: [
        {
          path: '/product/oral-semaglutide',
          name: 'oral-semaglutide',
          component: () => import('@/views/product/product-os.vue'),
        },
        {
          path: '/product/semaglutide-injection',
          name: 'semaglutide-injection',
          component: () => import('@/views/product/product-si.vue'),
        },
        // {
        //   path: '/product/oral-tirzepatide',
        //   name: 'oral-tirzepatide',
        //   component: () => import('@/views/product/product-ot.vue'),
        // },
      ],
    },
    {
      path: '/guides',
      name: 'guides',
      component: () => import('@/views/guides-page.vue'),
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('@/views/privacy-page.vue'),
    },
    {
      path: '/faq',
      name: 'faq',
      component: () => import('@/views/faq-page.vue'),
    },
    {
      path: '/terms',
      name: 'terms',
      component: () => import('@/views/terms-page.vue'),
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('@/views/contact-page.vue'),
    },
    {
      path: '/refund-policy',
      name: 'refund-policy',
      component: () => import('@/views/refund-policy-page.vue'),
    },
    {
      path: '/provider',
      name: 'provider',
      children: [
        {
          path: '/provider/semaglutide-injection-dosing',
          name: 'semaglutide-injection-dosing',
          component: () => import('@/views/provider/semaglutide-injection-dosing.vue'),
        },
        {
          path: '/provider/semaglutide-injection-dosing/beaker-pharmacy',
          name: 'beaker-pharmacy',
          component: () => import('@/views/provider/beaker-pharmacy.vue'),
        },
        {
          path: '/provider/semaglutide-injection-dosing/phi-pharmacy',
          name: 'phi-pharmacy',
          component: () => import('@/views/provider/phi-pharmacy.vue'),
        },
        {
          path: '/provider/semaglutide-injection-dosing/valiant-pharmacy',
          name: 'valiant-pharmacy',
          component: () => import('@/views/provider/valiant-pharmacy.vue'),
        },
        {
          path: '/provider/semaglutide-injection-dosing/pharmetika-pharmacy',
          name: 'pharmetika-pharmacy',
          component: () => import('@/views/provider/pharmetika-pharmacy.vue'),
        },
        {
          path: '/provider/oral-semaglutide-dosing',
          name: 'oral-semaglutide-dosing',
          component: () => import('@/views/provider/oral-semaglutide-dosing.vue'),
        },
        // {
        //   path: '/provider/oral-tirzepatide-dosing',
        //   name: 'oral-tirzepatide-dosing',
        //   component: () => import('@/views/provider/oral-tirzepatide-dosing.vue'),
        // },
        // {
        //   path: '/provider/oral-tirzepatide-dosing/foothills-pharmacy',
        //   name: 'foothills-pharmacy',
        //   component: () => import('@/views/provider/foothills-pharmacy.vue'),
        // },
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/404',
    },
  ],
})

export default router
