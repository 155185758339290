<template>
  <div
    id="section11"
    style="
      border-radius: 0 0 clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem)
        clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem);
      background-color: #fff;
      padding-top: calc(
        clamp(2.5rem, 1.3996478873239437rem + 4.694835680751173vw, 5.625rem) +
          clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem)
      );
      padding-bottom: clamp(2.5rem, 1.3996478873239437rem + 4.694835680751173vw, 5.625rem);
    "
  >
    <div
      class="m-auto w-full px-4 sm:max-w-[540px-to-767px] md:max-w-[720px-to-991px] lg:max-w-[960px-to-1279px] min-xl:max-w-[1232px]"
    >
      <h2
        class="section-title mt-6 max-xs:mt-4"
        style="
          margin: 0 auto;
          text-align: center;
          font-size: 1.75rem;
          margin-bottom: clamp(1.75rem, 1.045774647887324rem + 3.004694835680751vw, 3.75rem);
        "
      >
        <span>More from <span class="dark-green">our members</span></span>
      </h2>
    </div>
    <div>
      <div>
        <div id="section-11th" class="swiper still-scroll">
          <div class="swiper-wrapper">
            <div
              v-for="review in reviewList"
              :key="review.name"
              class="swiper-slide"
              style="width: clamp(18.125rem, 17.556818181818183rem + 2.02020202020202vw, 19.375rem)"
            >
              <ThumbUpSvg class="slide-thumb-up" />
              <div
                class="slide-item flex h-full w-full flex-col items-stretch justify-between gap-6 rounded-3xl text-primary active:cursor-grab"
              >
                <div class="flex items-start justify-between gap-6 max-sm:h-full max-sm:flex-col">
                  <div class="flex flex-col items-center justify-start">
                    <h4 class="text-[1.5rem] max-xs:text-[1.25rem] p-[28px]">
                      {{ review.name }}
                      <div class="flex w-full items-center justify-between">
                        <!-- Stars -->
                        <div
                          class="flex h-[38px] items-center gap-1 rounded-full px-4 max-xs:h-[28px] max-xs:px-3"
                          style="align-items: flex-end"
                        >
                          <div class="flex items-center pb-[1px]" style="margin-top: 0.9375rem;">
                            <img
                              src="@/assets/image/full-star.webp"
                              alt="star"
                              style="width: clamp(8.125rem, 7.521rem + 2.48vw, 9.75rem); height: clamp(1.25rem, 1.157rem + 0.38vw, 1.5rem)"
                            />
                          </div>
                        </div>
                      </div>
                    </h4>
                    <p class="slide-content text-lg max-xs:p-4" v-html="review.content"></p>
                    <div
                      class="flex items-center rounded-full"
                      style="gap: 0.31rem; padding: 0.62rem 2.5rem"
                    >
                      <VerifiedSvg />
                      <span class="verified">Thinly Weight Loss Program Member</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SlideRightSvg class="slide-right-icon swiper-next-button max-xs:hidden" />
          <SlideRightSvg class="slide-left-icon swiper-prev-button max-xs:hidden" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Swiper from 'swiper'
import 'swiper/css'
import '@/assets/extra-swiper.css'

import SlideRightSvg from '@/assets/svg/slide-right.svg'
import ThumbUpSvg from '@/assets/svg/thumbup.svg'
import VerifiedSvg from '@/assets/svg/verified.svg'
import { Navigation } from 'swiper/modules'
import { onMounted } from 'vue'
import { isMobile } from '@/utils/index'

const reviewList = [
  {
    name: 'Yuri M.',
    content: `“I was nervous going into it because I never tried medication to help with weight loss. I had lots of questions! I appreciated their customer service and chat being available for whenever something would come up. <p class="highlight">Things are going great!! Wish I would’ve started earlier!”</p>`,
  },
  {
    name: 'Lane L.',
    content: `<p class="highlight">“I had so many questions in the beginning, and both the doctor and customer support were so patient and thorough.</p> Very fair pricing for excellent results. If you’re on a weight loss journey, definitely give this company a try!”`,
  },
  {
    name: 'Mandy G.',
    content: `“I am now in month 3 and have lost 17 lbs in 9 weeks. <p class="highlight">I feel healthier. I don't have constant food cravings. My energy levels have increased.</p> I would recommend thinly to any of my friends and family looking to take charge of their health.”`,
  },
  {
    name: 'Sophie R.',
    content: `“Skeptical at first, but <p class="highlight">Everyday+ genuinely shifted how I manage weight.</p> Less effort, more results. It's been a pleasant surprise. thinly's support team has been professional and responsive, making my weight loss journey smooth and effective.”`,
  },
  {
    name: 'Carla S.',
    content: `<p class="highlight"><span style="color: #666; font-weight: 400">“</span>This company is exactly what they promote. Wellness and health.</p> Courteous and prompt. They respond in less than 24 hours. Product arrived within 7 days of ordering. Thank you to all the staff members and the doctor. I will definitely be ordering again.”`,
  },
  {
    name: 'Chelsea',
    content: `“Thinly's staff and doctors are incredibly responsive. Smooth journey with quick medication shipping and no missed doses. Down 25lbs, <span class="highlight">Thinly's metabolic health approach is refreshing and effective.</span> Highly recommend Thinly for anyone seeking professional service.”`,
  },
  {
    name: 'Jan L.',
    content: `“Thinly's service is professional and expedient. <span class="highlight">The support and responsiveness of their team are commendable.</span> Highly recommend Thinly for professional and efficient service, making my weight loss journey positive. The team has been incredibly helpful and supportive throughout.”`,
  },
  {
    name: 'Deborah',
    content: `“My experience with Thinly has been great. The process is easy, with quick responses and excellent updates. <span class="highlight">I recommend Thinly for anyone seeking professional and efficient service.</span> Their timely updates and responsiveness are outstanding. I'm very pleased with the overall service.”`,
  },
  {
    name: 'Margaret',
    content: `“I like Thinly so far. With fibromyalgia and weight-gaining meds, I've managed to lose 10lbs thanks to their effective program. <span class="highlight">The support from Thinly has been great, and I'm looking forward to continued progress on my weight loss journey.</span> Highly recommend their service to others.”`,
  },
  {
    name: 'Kacy',
    content: `“<span class="highlight">Do this now! Don't wait. Completely life changing.</span> A better value than any provider in my area. The customer service is top-notch and the program is easy to follow. Highly recommend Thinly's services for anyone looking for a supportive and effective weight loss program.”`,
  },
  {
    name: 'Terry',
    content: `“Thinly and the customer team have been FABULOUS! The service is professional and responsive, making my weight loss journey positive and encouraging. <span class="highlight">Highly recommend Thinly for their effective and supportive program.</span> The team has been very helpful and supportive throughout.”`,
  },
  {
    name: 'Jeneece',
    content: `“I have had trouble losing more than 5 pounds at any time for years. My cholesterol and blood pressure are high. I tried Thinly to get healthier and lose 15-18 pounds. I have no side effects from the injections at all. <span class="highlight">Just this week I was able to buy a size smaller in clothes!</span>”`,
  },
  {
    name: 'Bea D.',
    content: `Thinly is the BEST! Great experience. I appreciate everything! From the easy intake to the customer service, everything has been excellent. Highly recommend Thinly for their effective and supportive program. <span class="highlight">The team has been incredibly helpful and supportive.</span>”`,
  },
]

// 初始调用
onMounted(() => {
  new Swiper('#section-11th', {
    freeMode: true,
    observer: true,
    slidesPerView: 'auto',
    spaceBetween: isMobile() ? 16 : 50,
    modules: [Navigation],

    // Navigation arrows
    navigation: {
      nextEl: '#section11 .swiper-next-button',
      prevEl: '#section11 .swiper-prev-button',
    },
  })
})
</script>
<style lang="css" scoped>
.swiper {
  padding: 0 clamp(1rem, -6.057rem + 28.95vw, 20rem);
}
.slide-item {
  background-color: #f5efe6;
  user-select: none;
}
.slide-item h4 {
  color: #1a4d2e;
  text-align: center;
  font-family: 'San Francisco Display';
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: capitalize;
}
.slide-content {
  padding: 1.5rem 1.2rem;
  height: 329px;
  background-color: #eee3d2;
  color: #666;
  text-align: center;
  font-family: 'San Francisco Display';
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
  text-transform: capitalize;
}
.slide-thumb-up {
  position: absolute;
  right: 0;
  top: 4px;
}
.slide-left-icon,
.slide-right-icon {
  opacity: 1;
  cursor: pointer;
  transition: all 0.3s;
  z-index: 9999;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.swiper:hover .slide-left-icon:not(.swiper-button-disabled),
.swiper:hover .slide-right-icon:not(.swiper-button-disabled) {
  opacity: 1;
}

.slide-left-icon.swiper-button-disabled,
.slide-right-icon.swiper-button-disabled {
  opacity: 0;
}

.slide-right-icon {
  right: 1.5rem;
}
.slide-left-icon {
  left: 1.5rem;
  transform-origin: top;
  rotate: 180deg;
}
.verified {
  color: #9d6c46;
  font-family: Gloock;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  /** Slight adjust for visual */
  padding-top: 1px;
}
</style>
