<template>
  <div
    class="w-full"
    style="
      border-radius: 0 0 clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem)
        clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem);
      background-color: #f5efe6;
      padding-top: calc(
        clamp(2.5rem, 1.3996478873239437rem + 4.694835680751173vw, 5.625rem) +
          clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem)
      );
      padding-bottom: clamp(2.5rem, 1.3996478873239437rem + 4.694835680751173vw, 5.625rem);
    "
  >
    <div
      class="m-auto w-full px-4 sm:max-w-[540px-to-767px] md:max-w-[720px-to-991px] lg:max-w-[960px-to-1279px] min-xl:max-w-[1232px]"
    >
      <div class="gap grid grid-container">
        <div class="flex flex-col text-container" style="gap: 0.625rem">
          <h2
            class="section-title"
            style="margin-bottom: calc(clamp(1rem, 0.56rem + 1.88vw, 2.25rem) - 0.625rem)"
          >
            Start your health journey
            <p class="dark-green">Now</p>
          </h2>
          <div
            v-for="advantage in advantageList"
            :key="advantage"
            class="advantage min-sm:max-w-[32rem]"
            style="font-size: clamp(1.125rem, 0.993rem + 0.56vw, 1.5rem)"
          >
            <div>
              <CheckedSvg />
            </div>
            <p v-html="advantage"></p>
          </div>
          <button
            style="margin: clamp(1.25rem, 0.678rem + 2.44vw, 2.875rem) 0"
            class="button-gradient mt-1 max-xs:h-12 max-xs:text-base rounded-full tracking-[0.1em] h-14 flex items-center justify-center font-medium text-lg text-nowrap px-4 text-center gap-2 relative bg-secondary text-white w-full max-xs:w-full min-sm:max-w-[32rem]"
            type="button"
            @click="toSku"
          >
            Get Started
          </button>
        </div>
        <img
          loading="lazy"
          draggable="false"
          :src="ImageSrc"
          class="w-full max-sm:justify-self-center min-md:justify-self-end"
          alt="medication"
          style="
            width: 100%;
            height: 100%;
            max-width: clamp(21.9375rem, 14.851232394366198rem + 30.234741784037556vw, 42.0625rem);
            border-radius: 1.5rem;
          "
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import ImageSrc from '@/assets/image/start-your-healthy-journey-now/start-your-healthy-journey-now.webp'
import CheckedSvg from '@/assets/svg/checked.svg'
import { toSku } from '@/utils'

const advantageList = [
  'On-demand medical support, <span class="highlight dark">100% online</span>',
  'Transparent pricing for <span class="highlight dark">every single</span> dose',
  'Tested semaglutide lots for <span class="highlight dark">potency, sterility, pH and endotoxicity</span>',
]
</script>
<style scoped>
button {
  color: #fff;
  font-family: 'San Francisco Display';
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.advantage {
  display: flex;
  padding: 0.625rem;
  padding-right: clamp(1.5625rem, 0.7954545454545455rem + 2.727272727272727vw, 3.25rem);
  border-radius: 0.625rem;
  background-color: #e8dfca;
  align-items: center;
  gap: 1.25rem;
}
.advantage p {
  max-width: 25.25rem;
  font-family: 'San Francisco Display';
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 187.5%;
  text-transform: capitalize;
}
.text-container {
  padding-top: clamp(0rem, -2.042rem + 8.71vw, 5.8rem);
}
.grid-container {
  align-items: flex-start;
  grid-template-columns: 0.8fr 1.2fr;
}
@media (max-width: 1023px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
  .grid-container > * {
    justify-self: center;
  }
}
</style>
