<template>
  <em class="safety-desc">
    *Compounded medications may be prescribed by physicians but have not been FDA regulated for
    safety, effectiveness, or quality. FDA-approved medicines containing semaglutide are available.
  </em>
  <h3 class="safety-title">WARNING</h3>
  <p>
    Please contact your doctor ASAP and seek in-person care immediately if a side effect becomes
    problematic or you experience:
  </p>
  <ul>
    <li>
      Severe nausea and/or vomiting resulting in dehydration - it is important to stay well hydrated
      and drink plenty of fluids while on this medication.
    </li>
    <li>
      Kidney problems/kidney failure - vomiting, nausea, diarrhea can cause dehydration and kidney
      issues and make kidney problems worse in individuals who have pre-existing kidney conditions;
      may sometimes lead to the need for hemodialysis.
    </li>
    <li>
      Thyroid C-Cell Tumor: Trouble swallowing, hoarseness, a lump or swelling in your neck, or
      shortness of breath (see black box warning below).
    </li>
    <li>Pancreatitis: Severe pain in your abdomen or back that will not go away.</li>
    <li>
      Acute gallbladder disease - Pain in the middle or right upper stomach, fever, white parts of
      your eyes turn yellow or skin turns yellow, nausea/vomiting. If gallstones or inflammation of
      the gallbladder is suspected further studies are needed.
    </li>
    <li>Hepatitis/Elevated liver enzymes/Jaundice</li>
    <li>
      Diabetic retinopathy problems: Changes in vision in those with type II diabetes, particularly
      vision problems may worsen in those with a history of diabetic retinopathy.
    </li>
    <li>
      Severe gastrointestinal disease - may cause gastrointestinal disease; do not use if you have a
      history of severe gastrointestinal disease
    </li>
    <li>
      Signs of low blood sugar (for example, dizziness, weakness, shakiness, jitteriness, headache,
      anxiety/mood changes, rapid heartbeat, confusion, slurred speech, sweating, or
      lightheadedness). While this is rare with GLP-1s, if it occurs, please seek in-person care
      immediately. If you have these symptoms, try having liquid or food containing sugar (around
      15-20 grams) —drinking juice, having regular soda, or eating some hard candies right away, as
      this will help raise your blood sugar quickly. If taken with insulin or an insulin
      secretagogue (i.e. sulfonylurea such as Glipizide, glimepiride, and glyburide) it is best to
      talk to your healthcare provider about possibly lowering the dose of these medications to
      lower the risk of hypoglycemia.
    </li>
    <li>Increasing heart rate</li>
    <li>Worsening depression, behavior changes, or suicidality</li>
  </ul>
  <p>Never share the semaglutide with other people</p>
  <h3 class="safety-title">Drug Interactions</h3>
  <p>
    As with other medications, oral semaglutide can interact with alcohol and certain prescription
    and non-prescription medications and herbal products. The risk of hypoglycemia is increased when
    combined with insulin or insulin secretagogues such as sulfonylureas. Oral semaglutide delays
    gastric emptying and may impact the absorption of oral medications. If you are on thyroid
    hormone medication, prior to starting oral semaglutide, please review the timing of taking your
    thyroid hormone medication with the doctor who prescribes it as it may need to be adjusted. The
    drug levels of medications with narrow therapeutic windows (ie warfarin) should be monitored
    closely while on oral semaglutide.
  </p>
  <p>
    Please consult the pharmaceutical packaging for a full list of side effects, warnings,
    interactions and additional information.
  </p>
  <p>
    To report suspected adverse reactions to o semaglutide, contact the FDA at 1-800-FDA-1088 or
    <a href="https://www.fda.gov/medwatch">www.fda.gov/medwatch</a>.
  </p>
  <p>In case of emergencies, please call 911 or go to the nearest emergency room.</p>
  <p>
    <strong>Note:</strong> The above statements have not been evaluated by the Food and Drug Administration. This
    product is not intended to diagnose, treat, cure, or prevent any disease.
  </p>
  <h3 class="safety-title">BLACK BOX WARNING</h3>
  <p>
    GLP-1s are contraindicated in individuals with a history of medullary thyroid carcinoma, a
    family history of such, or in patients with multiple endocrine neoplasia syndrome type 2. Do not
    take them if you have ever had thyroid cancer. In studies with rodents, GLP-1s caused thyroid
    tumors, including thyroid cancer; it is not known if they will cause thyroid tumors or cancer in
    humans. Please notify your doctor ASAP if you develop trouble swallowing, hoarseness, a lump or
    swelling in your neck, or shortness of breath.
  </p>
  <p>If you experience a medical emergency, call 911 or go to the nearest emergency room.</p>
  <p>Tell your healthcare provider about your medical history prior to taking semaglutide.</p>
  <p>
    Avoid alcohol while taking this medication. If you’re taking diabetes medications, please
    discuss this with your prescribing doctor before starting semaglutide, as your diabetes
    medications may need to be adjusted as you lose weight.
  </p>
  <p>
    Use adequate contraception while taking this medication. Do not take this medication if
    pregnant, trying to get pregnant or breastfeeding. If you plan to become pregnant, this
    medication needs to be stopped at least 2 months before trying. This medication may cause fetal
    harm.
  </p>
  <p>Notify your doctor if you have kidney, liver, or pancreas problems.</p>
  <p>
    Stop using semaglutide and call your healthcare provider immediately if you have severe stomach
    or abdomen pain that will not go away, with or without vomiting.
  </p>
  <p>
    Stop using semaglutide and get medical help right away if you have any symptoms of a serious
    allergic reaction, including swelling of your face, lips, tongue, or throat; problems breathing
    or swallowing; severe rash or itching; fainting or feeling dizzy; or very rapid heartbeat.
  </p>
  <p>
    Serious hypersensitivity reactions (e.g., anaphylaxis and angioedema) have been reported.
    Discontinue semaglutide if suspected and promptly seek medical advice.
  </p>
</template>
<style lang="scss" scoped>
em,
p,
h3 {
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 0.625rem;
}

a {
  opacity: 0.5;
  transition: all 0.3s;
  color: #121517;
  text-decoration: underline;
  background-color: #0000;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  unicode-bidi: isolate;
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-top: 1.25rem;
  margin-bottom: 1.875rem;
  padding-left: 1.5rem;
}

li {
  padding-bottom: 8px;
  line-height: 1.3;
}

.safety-title {
  margin-bottom: 0;
  padding-top: 30px;
  padding-bottom: 10px;
  font-weight: 600;
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  line-height: 1.3;
}

@media screen and (max-width: 767px) {
  p,
  em {
    font-size: 1rem;
    line-height: 1.4;
  }
}

@media screen and (max-width: 479px) {
  h3 {
    font-size: 1.25rem;
  }
}
</style>
