<template>
  <div class="test-box" :style="{ height: computedHeight }">
    <div ref="headerRef" class="flex test-header items-center">
      <div class="test-box-title">{{ title }}</div>
      <div class="test-box-status">
        <img
          draggable="false"
          style="width: 1rem; height: 1rem;"
          :loading="loadingMode"
          alt="success icon"
          src="@/assets/image/success.webp"
        />
        <span>Passed</span>
      </div>
    </div>
    <div
      :class="{
        'collapsed-content': true,
        'is-expanded': isExpanded,
      }"
      ref="collapsedRef"
    >
      <slot name="collapsed"></slot>
    </div>
    <div
      :class="{
        'expanded-content': true,
        'is-expanded': isExpanded,
      }"
      ref="expandedRef"
    >
      <slot name="expanded"></slot>
    </div>
    <div v-if="useExpandBox" ref="moreRef" class="flex test-box-more-container">
      <div class="test-box-more cursor-pointer" @click.stop="toggleExpand">
        <div class="add-icon">
          <img
            alt="expand icon"
            :class="{ 'is-expanded-icon': isExpanded }"
            draggable="false"
            :loading="loadingMode"
            src="@/assets/image/add.webp"
          />
        </div>
        <span>{{ buttonText }}</span>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, useTemplateRef } from 'vue'

const props = withDefaults(
  defineProps<{
    title: string
    initExpanded?: boolean
    firstOffset?: number
    useExpandBox?: boolean
    loadingMode?: 'eager' | 'lazy'
  }>(),
  {
    loadingMode: 'lazy',
  },
)

const boxHeaderRef = useTemplateRef('headerRef')
const boxExpandedRef = useTemplateRef('expandedRef')
const boxCollapsedRef = useTemplateRef('collapsedRef')
const boxMoreRef = useTemplateRef('moreRef')

let interval: NodeJS.Timeout | null = null

const isExpanded = ref(props.useExpandBox ? (props.initExpanded ?? false) : false)
const buttonText = computed(() => (isExpanded.value ? 'Close' : 'Learn More'))

const computedHeight = ref()

const toggleExpand = async () => {
  isExpanded.value = !isExpanded.value
  await getTextBoxDomHeight()
}
const getTextBoxDomHeight = async () => {
  const lastTimeHeight = {
    header: 0,
    expanded: 0,
    collapsed: 0,
    more: 0,
  }

  interval = setInterval(async () => {
    if (interval) clearInterval(interval)
    const headerHeight = boxHeaderRef.value?.offsetHeight ?? 0
    const expandedHeight = boxExpandedRef.value?.offsetHeight ?? 0
    const collapsedHeight = boxCollapsedRef.value?.offsetHeight ?? 0
    const moreHeight = boxMoreRef.value?.offsetHeight ?? 0
    lastTimeHeight.header = headerHeight
    lastTimeHeight.expanded = expandedHeight
    lastTimeHeight.collapsed = collapsedHeight
    lastTimeHeight.more = moreHeight
    if (
      lastTimeHeight.header === headerHeight &&
      lastTimeHeight.expanded === expandedHeight &&
      lastTimeHeight.collapsed === collapsedHeight &&
      lastTimeHeight.more === moreHeight
    ) {
      return
    }
    const res = `calc(${expandedHeight + headerHeight + collapsedHeight + moreHeight}px + 1.3rem + 1.88rem + clamp(0.63rem, 0.412rem + 0.93vw, 1.25rem) + clamp(0.63rem, 0.412rem + 0.93vw, 1.25rem))`
    computedHeight.value = res
    return res
  }, 100)
}

const handleResize = async () => {
  await getTextBoxDomHeight()
}
onMounted(async () => {
  getTextBoxDomHeight()
  window.addEventListener('resize', handleResize)
  window.addEventListener('orientationchange', handleResize)
})

onUnmounted(() => {
  if (interval) clearInterval(interval)
  window.removeEventListener('resize', handleResize)
  window.removeEventListener('orientationchange', handleResize)
})
</script>
<style lang="scss" scoped>
* {
  transition: all ease-in-out 0.3s;
}
.expanded-content {
  height: 0;
  overflow: hidden;
}
.collapsed-content {
  height: max-content;
  overflow: hidden;
}
.is-expanded {
  &.collapsed-content {
    height: 0;
  }
  &.expanded-content {
    height: max-content;
  }
}
.is-expanded-icon {
  transform: rotate(45 + 180deg);
}
.test-box {
  border-radius: 0.625rem;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  padding: 1.3rem 1.88rem 1.88rem 1.88rem;
  margin-bottom: -0.63rem;
  width: 25rem;
  overflow: hidden;
}
.test-box-status {
  width: 6.875rem;
  height: 2.125rem;
  border-radius: 2.0625rem;
  background: var(--button, linear-gradient(90deg, #1a4d2e 0%, #5c9f62 100%));
  line-height: 2.125rem;
  padding-left: 0.62rem;
  display: flex;
  align-items: center;
}
.test-box-status span {
  color: #fff;
  font-family: 'San Francisco Display';
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 0.75rem;
}
.test-box-process .label {
  color: #999;
  font-family: 'San Francisco Display';
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.test-box-process {
  display: flex;
  align-items: center;
  margin-right: 2.5rem;
  margin-top: 1.25rem;
}
.test-header {
  justify-content: space-between;
  margin-bottom: clamp(0.63rem, 0.412rem + 0.93vw, 1.25rem);
}
.test-box-more-container {
  margin-top: clamp(0.63rem, 0.412rem + 0.93vw, 1.25rem);
}
.test-box-more {
  display: flex;
}
.test-box-more span {
  color: #5b5a5a;
  font-family: 'San Francisco Display';
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.add-icon {
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
  background-color: #999;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.62rem;
}

@media (min-width: 991px) {
  .test-box-title {
    color: #333;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
}
@media (max-width: 991px) {
  .test-box-status {
    width: 6.875rem;
    height: 2.31456rem;
  }
  .test-box-title {
    color: #333;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
  .test-box-process {
    margin-top: 1rem;
  }
  .test-box p {
    margin-top: 1rem;
  }
  .test-box-more {
    margin-top: 0.62rem;
  }
  .test-box {
    width: 100%;
    max-width: 25rem;
  }
}
</style>
