<template>
  <section id="section2" class="bg-default section-2-container">
    <div
      class="w-full bg-white section-2-container-body-upper"
      :style="{
        paddingTop: `calc(${getClamp(50 + 30, 90 + 40)})`,
        paddingBottom: getClamp(20, 60),
      }"
    >
      <div
        class="m-auto w-full px-4 sm:max-w-[540px-to-767px] md:max-w-[720px-to-991px] lg:max-w-[960px-to-1279px] min-xl:max-w-[1232px]"
      >
        <h2 id="sku" class="section-title text-center">
          Clinically proven <span class="dark-green">weight loss treatments</span>
        </h2>
      </div>
    </div>
    <div class="bg-white flex flex-col justify-center">
      <div id="section-2nd" class="swiper w-full">
        <div class="swiper-wrapper w-full">
          <div
            class="swiper-slide swiper-slide-active flex flex-col items-center max-w-[470px] active:cursor-grab product"
            :class="{ 'margin-right-0': index === productInfoList.length - 1 }"
            v-for="(product, index) in productInfoList"
            :key="product.title"
            :style="{
              width: getClamp(292, 470),
            }"
          >
            <div
              class="relative w-full overflow-hidden rounded-[24px]"
              :style="{ height: getClamp(438, 580), width: getClamp(292, 470) }"
            >
              <div
                class="flex flex-col text-base font-bold text-black rounded-none w-full h-full"
                role="article"
              >
                <div
                  class="flex flex-col w-full h-full product-container"
                  :style="{
                    backgroundColor: product.backgroundColor,
                  }"
                >
                  <div
                    class="flex relative flex-col items-start w-full h-full product-container-inner"
                  >
                    <img
                      :style="{
                        ...product.style,
                      }"
                      loading="lazy"
                      :src="product.image"
                      alt="Oral Semaglutide medication package"
                      class="object-contain absolute inset-0 product-image"
                      draggable="false"
                    />
                    <h2 class="relative product-title">{{ product.title }}</h2>
                    <p class="relative product-price">{{ product.price }}</p>
                    <div
                      class="flex relative product-badge items-center justify-center"
                      role="status"
                      aria-live="polite"
                    >
                      <div
                        class="flex shrink-0 my-auto w-2 h-2 rounded-full"
                        :style="{ backgroundColor: product.statusColor }"
                        aria-hidden="true"
                      ></div>
                      <span :style="{ color: product.statusColor }">In stock</span>
                    </div>
                    <button
                      class="relative button-gradient hover:opacity-90 product-button"
                      type="button"
                      aria-label="Get Started"
                      @click="() => router.push(product.productLink)"
                    >
                      Get Started
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="mt-2 text-info"
              @click="
                handleClickImportantSafetyInformation({
                  safetyTitle: product.safetyTitle ?? '',
                  safetyInfo: product.safetyInfo,
                })
              "
            >
              Important safety information
            </div>
          </div>
        </div>
        <SlideRightSvg class="slide-right-icon swiper-next-button max-xs:hidden" />
        <SlideRightSvg class="slide-left-icon swiper-prev-button max-xs:hidden" />
      </div>
    </div>
    <div class="w-full bg-white section-2-container-body-other">
      <div
        class="description flex flex-col rounded-xl bg-default m-auto w-full text-xs text-default"
      >
        <span>
          Prescription medication only available if prescribed after an online consultation with a
          healthcare provider. Physicians may prescribe compounded medications as needed to meet
          patient requirements or drug shortages. The FDA does not review or approve any compounded
          medications for safety or effectiveness. Results may vary.
        </span>
        <span class="mt-3">
          Plans are offered as a subscription service which you can cancel at any time.. Actual
          product packaging may appear differently than shown.
        </span>
      </div>
    </div>
    <ElDrawer
      v-if="currentSafetyInfo.comp"
      v-model="drawerVisible"
      :z-index="999999"
      :title="currentSafetyInfo.title"
      :show-close="false"
      size="100%"
      append-to-body
      lock-scroll
    >
      <template #header>
        <h2
          style="
            font-size: 2rem;
            font-family: Gloock;
            padding: 1.875rem 1.875rem 0 0;
            line-height: 1.1;
            color: #000;
          "
        >
          {{ currentSafetyInfo.title }}
        </h2>
        <div
          class="slideouts-close w-inline-block cursor-pointer"
          @click="() => (drawerVisible = false)"
        >
          <div class="close-i w-embed">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
              <g>
                <line x1="3.64" y1="3.22" x2="16.19" y2="15.78"></line>
                <line x1="16.19" y1="3.22" x2="3.64" y2="15.78"></line>
              </g>
            </svg>
          </div>
        </div>
      </template>
      <component :is="currentSafetyInfo.comp" />
    </ElDrawer>
  </section>
</template>
<script lang="ts" setup>
import Swiper from 'swiper'
import 'swiper/css'
import '@/assets/extra-swiper.css'

import { ElDrawer } from 'element-plus'
import { FreeMode, Navigation } from 'swiper/modules'
import { markRaw, onMounted, ref, watch, type Component } from 'vue'
import { isMobile, getClamp } from '@/utils/index'
import { useRouter } from 'vue-router'

import SlideRightSvg from '@/assets/svg/slide-right.svg'

import SkuOralSemaglutideImage from '@/assets/image/sku/Oral-Semaglutide.webp'
import SkuInjectableSemaglutideImage from '@/assets/image/sku/Semaglutide-Injection.webp'
// import SkuOralTirzepatideImage from '@/assets/image/sku/Oral-Tirzepatide.webp'

import OralSemaglutideSafetyInfo from '../safety-info/oral-semaglutide-safety-info.vue'
import InjectableSemaglutideSafetyInfo from '../safety-info/injectable-semaglutide-safety-info.vue'
// import OralTirzepatideSafetyInfo from '../safety-info/oral-tirzepatide-safety-info.vue'

const router = useRouter()

const h2Ref = ref()
const firstSwiperMarginLeft = ref(0)

const drawerVisible = ref(false)

const currentSafetyInfo = ref<{ comp?: Component | null; title: string }>({
  comp: null,
  title: '',
})

const productInfoList = ref([
  {
    image: SkuOralSemaglutideImage,
    title: 'Oral Semaglutide',
    price: 'Starting at $179/mo',
    style: {
      width: 'clamp(9rem, 7.79rem + 5.16vw, 12.438rem)',
      height: 'clamp(26.875rem, 23.288rem + 15.31vw, 37.063rem)',
      left: 'clamp(8.125rem, 6.232rem + 8.08vw, 13.5rem)',
      top: 'calc(-1 * clamp(-3.688rem, -4.106rem + 1.78vw, -2.5rem))',
    },
    status: 'In stock',
    statusColor: '#4F6F52',
    backgroundColor: '#DEE6DF',
    productLink: '/product/oral-semaglutide',
    safetyTitle: 'Oral Semaglutide GLP-1',
    safetyInfo: markRaw(OralSemaglutideSafetyInfo),
  },
  {
    image: SkuInjectableSemaglutideImage,
    title: 'Semaglutide Injection',
    price: 'Starting at $199/mo',
    style: {
      width: 'clamp(15.5rem, 12.023rem + 14.84vw, 25.375rem)',
      height: 'clamp(21.875rem, 20.643rem + 5.26vw, 25.375rem)',
      top: 'clamp(6rem, 6.129rem + 3.72vw, 9.473rem)',
      left: 'clamp(4.5rem, 3.334rem + 4.98vw, 7.813rem)',
    },
    status: 'In stock',
    statusColor: '#4F6F52',
    backgroundColor: '#F5EFE6',
    productLink: '/product/semaglutide-injection',
    safetyTitle: 'Compounded Semaglutide',
    safetyInfo: markRaw(InjectableSemaglutideSafetyInfo),
  },
  // {
  //   image: SkuOralTirzepatideImage,
  //   title: 'Oral Tirzepatide',
  //   price: 'Starting at $199/mo',
  //   style: {
  //     width: 'clamp(9rem, 7.79rem + 5.16vw, 12.438rem)',
  //     height: 'clamp(26.875rem, 23.288rem + 15.31vw, 37.063rem)',
  //     left: 'clamp(8.125rem, 6.585rem + 6.57vw, 12.5rem)',
  //     top: 'calc(-1 * clamp(-3.688rem, -4.106rem + 1.78vw, -2.5rem))',
  //   },
  //   status: 'In stock',
  //   statusColor: '#4F6F52',
  //   backgroundColor: '#E8DFCA',
  //   productLink: '/product/oral-tirzepatide',
  //   safetyTitle: 'Compounded Tirzepatide',
  //   safetyInfo: markRaw(OralTirzepatideSafetyInfo),
  // },
])

const handleClickImportantSafetyInformation = (product: {
  safetyInfo: Component | null | undefined
  safetyTitle: string
}) => {
  currentSafetyInfo.value = {
    comp: product.safetyInfo,
    title: product.safetyTitle,
  }
  drawerVisible.value = true
}

// 初始调用
onMounted(() => {
  new Swiper('#section-2nd', {
    freeMode: true,
    observer: true,
    slidesPerView: 'auto',
    spaceBetween: isMobile() ? 16 : 40,
    modules: [FreeMode, Navigation],
    // Navigation arrows
    navigation: {
      nextEl: '#section-2nd .swiper-next-button',
      prevEl: '#section-2nd .swiper-prev-button',
    },
  })
  if (h2Ref.value && !isMobile()) {
    const rect = h2Ref.value.getBoundingClientRect()
    const distance = rect.left + window.scrollX
    firstSwiperMarginLeft.value = distance
  }
})

watch(
  () => drawerVisible.value,
  (val) => {
    if (val) {
      document.body.classList.add('no-scroll')
    } else {
      if (document.body.classList.contains('no-scroll')) {
        document.body.classList.remove('no-scroll')
      }
    }
  },
)
</script>

<style lang="scss" scoped>
.margin-right-0 {
  margin-right: 0!important;
}
.text-info {
  color: #000;
  font-family: 'San Francisco Display';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: 1px;
  text-underline-position: from-font;
  cursor: pointer;
  text-align: center;
}
.description {
  padding: clamp(0.625rem, 0.405rem + 0.94vw, 1.25rem);
  width: clamp(20.688rem, 6.405rem + 60.94vw, 61.25rem);
  overflow: hidden;
  color: #c6af8d;
  font-family: 'San Francisco Display';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.product {
  &-container {
    border-radius: clamp(0.938rem, 0.607rem + 1.41vw, 1.875rem);
    overflow: hidden;
    &-inner {
      padding: clamp(1.25rem, 0.81rem + 1.88vw, 2.5rem);
    }
  }
  &-title {
    color: #000;
    font-size: clamp(1.5rem, 1.412rem + 0.38vw, 1.75rem);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: clamp(0.375rem, 0.287rem + 0.38vw, 0.625rem);
  }
  &-price {
    color: #000;
    font-size: clamp(0.875rem, 0.831rem + 0.19vw, 1rem);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: clamp(0.625rem, 0.405rem + 0.94vw, 1.25rem);
  }
  &-badge {
    border-radius: 1.0625rem;
    background: #fff;
    width: clamp(5.5rem, 5.456rem + 0.19vw, 5.625rem);
    height: clamp(1.75rem, 1.618rem + 0.56vw, 2.125rem);
    gap: 0.38rem;
    span {
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      font-size: clamp(0.875rem, 0.831rem + 0.19vw, 1rem);
    }
  }
  &-button {
    border-radius: 2.0625rem;
    margin-top: clamp(16.625rem, 15.129rem + 6.38vw, 20.875rem);
    width: 100%;
    height: clamp(2.625rem, 2.449rem + 0.75vw, 3.125rem);
    color: #fff;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.section-2-container-body-upper {
  border-top-left-radius: clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem);
  border-top-right-radius: clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem);
}
.section-2-container-body-other {
  border-bottom-left-radius: clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem);
  border-bottom-right-radius: clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem);
  padding-top: clamp(1.06rem, -0.109rem + 4.99vw, 4.38rem);
  padding-bottom: clamp(2.705rem, 2.337rem + 1.57vw, 3.75rem);
  margin-bottom: -1.875rem;
}
@media (min-width: 1280px) {
  .section-2-container-body-other {
    margin-bottom: 0rem;
  }
}
.slide-left-icon,
.slide-right-icon {
  opacity: 1;
  cursor: pointer;
  transition: all 0.3s;
  z-index: 9999;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.swiper:hover .slide-left-icon:not(.swiper-button-disabled),
.swiper:hover .slide-right-icon:not(.swiper-button-disabled) {
  opacity: 1;
}

.slide-left-icon.swiper-button-disabled,
.slide-right-icon.swiper-button-disabled {
  opacity: 0;
}

.slide-right-icon {
  right: 1.5rem;
}
.slide-left-icon {
  left: 1.5rem;
  transform-origin: top;
  rotate: 180deg;
}
@media (max-width: 1280px) {
  .section-2-container {
    margin-bottom: 0;
  }
  .section-2-container-body {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@media screen and (max-width: 479px) {
  .slideouts-close {
    width: 3.125rem;
    height: 3.125rem;
    top: 1.25rem;
    right: 1.25rem;
  }
  .close-i {
    width: 1.5625rem;
    height: 1.5625rem;
    margin-top: 0.78125rem;
    margin-left: 0.78125rem;
  }
}
.slideouts-close {
  z-index: 30;
  background-color: #121517;
  border-radius: 3.125rem;
  width: 3.75rem;
  height: 3.75rem;
  transition: all 0.3s;
  position: absolute;
  inset: 1.875rem 1.875rem auto auto;
  svg {
    fill: none;
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 0.075rem;
  }
  svg:not(:root) {
    overflow: hidden;
  }
}
.w-embed:before,
.w-embed:after {
  content: ' ';
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}
.close-i {
  width: 1.875rem;
  height: 1.875rem;
  margin-top: 0.9375rem;
  margin-left: 0.9375rem;
}

@media (min-width: 1920.1px) {
  .section-title {
    justify-self: center;
    text-align: center;
  }
}
</style>
