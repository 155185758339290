<script setup lang="ts">
import CommonHeader from '../components/layout/common-header.vue'
import Section1 from '../components/home-page/section-1st.vue'
import Section2 from '../components/home-page/section-2nd.vue'
import Section3 from '../components/home-page/section-3rd.vue'
import Section4 from '../components/home-page/section-4th.vue'
import Section5 from '../components/home-page/section-5th.vue'
import Section6 from '../components/home-page/section-6th.vue'
import Section7 from '../components/home-page/section-7th.vue'
import Section8 from '../components/home-page/section-8th.vue'
import Section9 from '../components/home-page/section-9th.vue'
import Section10 from '../components/home-page/section-10th.vue'
import Section11 from '../components/home-page/section-11th.vue'
import Section12 from '../components/home-page/section-12th.vue'
import CommonFooter from '../components/layout/common-footer.vue'

import { getClamp } from '@/utils'
import { trackEvent } from '@/utils/fb-pixel.ts'

trackEvent('PageView')
</script>

<template>
  <CommonHeader show-navigators>
    <template #button>
      <a
        href="https://portal.thinly.com/sign-in"
        class="flex items-center justify-center w-20 rounded-full font-medium text-xs text-white button-gradient max-md:bg-gradient"
        :style="{ height: getClamp(30, 34) }"
      >
        Login
      </a>
    </template>
  </CommonHeader>
  <Section1 />
  <Section2 class="section-container index-12" />
  <Section3 class="section-container index-11" />
  <Section4 class="section-container index-10" />
  <Section5 class="section-container index-9" />
  <Section6 class="section-container index-8" />
  <Section7 class="section-container index-7" />
  <Section8 class="section-container index-6" />
  <Section9 class="section-container index-5" />
  <Section10 class="section-container index-4" />
  <Section11 class="section-container index-3" />
  <Section12 class="section-container index-2" />
  <CommonFooter />
</template>
