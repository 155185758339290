<template>
  <section class="bg-gradient rounded-3xl section-3-container">
    <div
      class="relative flex flex-col items-center m-auto w-full px-4 sm:max-w-[540px-to-767px] md:max-w-[720px-to-991px] lg:max-w-[960px-to-1279px] min-xl:max-w-[1342px]"
    >
      <h2 class="section-title">Member <span class="dark-green">success</span> stories</h2>
      <div
        class="grid grid-cols-2 gap-10 max-md:grid-cols-1 max-md:gap-5"
        :style="{ marginTop: getClamp(30, 78), marginBottom: getClamp(50, 60) }"
      >
        <div class="flex flex-col rounded-3xl drug-container">
          <div class="relative w-full overflow-hidden rounded-[24px] bg-default">
            <ImageSlider :image-before="image1" :image-after="image2" />
            <div class="non-selectable bg-white desc-box">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <div class="drug-box" style="background-color: #dee6df">
                    <img
                      loading="lazy"
                      draggable="false"
                      :src="SkuOralSemaglutideImage"
                      class="w-full h-full"
                      alt="sku-oral-semaglutide"
                    />
                  </div>
                  <span class="drug-name">Oral Semaglutide</span>
                </div>
                <button
                  class="button-gradient flex items-center justify-center rounded-full p-2 font-medium tracking-[0.1em] text-white start-button"
                  @click="router.push('/product/oral-semaglutide')"
                >
                  Get Started
                </button>
              </div>
              <div class="drug-title">
                Linda lost <span class="green">33 lbs</span><br />
                in 4 months
              </div>
              <div class="drug-desc">
                "I was tired of feeling tired at 185 pounds. From April to August, I've dropped to
                152 pounds, and it's amazing! My joints don't hurt anymore when I go hiking, and
                I've started taking yoga classes - something I never had the confidence to do
                before. Thinly made everything so straightforward and manageable."
              </div>
            </div>
            <div
              class="non-selectable flex items-center rounded-full justify-center"
              style="
                gap: 0.31rem;
                padding: clamp(0.813rem, 0.658rem + 0.66vw, 1.25rem)
                  clamp(2.313rem, 2.246rem + 0.28vw, 2.5rem)
                  clamp(0.813rem, 0.658rem + 0.66vw, 1.25rem)
                  clamp(2.313rem, 1.872rem + 1.88vw, 3.563rem);
                background-color: #e8dfca;
                border-radius: 0%;
              "
            >
              <VerifiedSvg />
              <span class="verified">Thinly Weight Loss Program Member</span>
            </div>
          </div>
        </div>
        <div class="w-full flex flex-col drug-container rounded-3xl">
          <div class="relative w-full overflow-hidden rounded-[24px] bg-default">
            <ImageSlider :image-before="image3" :image-after="image4" />
            <div class="non-selectable bg-white desc-box">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <div class="drug-box" style="background-color: #f5efe6">
                    <img
                      loading="lazy"
                      draggable="false"
                      :src="SkuInjectableSemaglutideImage"
                      class="w-full h-full"
                      alt="sku-injectable-semaglutide"
                    />
                  </div>
                  <span class="drug-name">Semaglutide Injection</span>
                </div>
                <button
                  class="button-gradient flex items-center justify-center rounded-full p-2 font-medium tracking-[0.1em] text-white start-button"
                  @click="router.push('/product/semaglutide-injection')"
                >
                  Get Started
                </button>
              </div>
              <div class="drug-title">
                Jenny lost <span class="green">28 lbs</span><br />
                in 4 months
              </div>
              <div class="drug-desc">
                "Post pregnancy, I was stuck at 165 pounds and couldn't seem to lose the baby
                weight. From May to September, I've gotten down to 137 pounds! As a busy mom,
                Thinly's program fit perfectly into my schedule. Now I can keep up with my toddler
                all day long!"
              </div>
            </div>
            <div
              class="non-selectable flex items-center rounded-full justify-center"
              style="
                gap: 0.31rem;
                padding: clamp(0.813rem, 0.658rem + 0.66vw, 1.25rem)
                  clamp(2.313rem, 2.246rem + 0.28vw, 2.5rem)
                  clamp(0.813rem, 0.658rem + 0.66vw, 1.25rem)
                  clamp(2.313rem, 1.872rem + 1.88vw, 3.563rem);
                background-color: #e8dfca;
                border-radius: 0%;
              "
            >
              <VerifiedSvg />
              <span class="verified">Thinly Weight Loss Program Member</span>
            </div>
          </div>
        </div>
        <!-- <div class="flex flex-col drug-container rounded-3xl">
          <div class="relative w-full overflow-hidden rounded-[24px] bg-default">
            <ImageSlider :image-before="image5" :image-after="image6" />
            <div class="non-selectable bg-white desc-box">
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <div class="drug-box" style="background-color: #d0bf96">
                    <img loading="lazy" draggable="false" :src="SkuOralTirzepatideImage" class="w-full h-full" alt="sku-oral-tirzepatide" />
                  </div>
                  <span class="drug-name">Oral Tirzepatide</span>
                </div>
                <button
                  class="button-gradient flex items-center justify-center rounded-full p-2 font-medium tracking-[0.1em] text-white start-button"
                  @click="router.push('/product/oral-tirzepatide')"
                >
                  Get Started
                </button>
              </div>
              <div class="drug-title">
                Mike lost <span class="green">52 lbs</span><br />
                in 6 months
              </div>
              <div class="drug-desc">
                "My doctor warned me about my blood pressure last December when I was 256 pounds.
                Today, in June, I'm at 204 and my numbers are much better. Working at a desk job, I
                never thought I could achieve this. Thinly made it possible with their simple
                approach. My kids are proud of their dad!"
              </div>
            </div>
            <div
              class="non-selectable flex items-center rounded-full justify-center"
              style="
                gap: 0.31rem;
                padding: clamp(0.813rem, 0.658rem + 0.66vw, 1.25rem)
                  clamp(2.313rem, 2.246rem + 0.28vw, 2.5rem)
                  clamp(0.813rem, 0.658rem + 0.66vw, 1.25rem)
                  clamp(2.313rem, 1.872rem + 1.88vw, 3.563rem);
                background-color: #e8dfca;
                border-radius: 0%;
              "
            >
              <VerifiedSvg />
              <span class="verified">Thinly Weight Loss Program Member</span>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="non-selectable marquee">
      <div class="marquee-content" :style="{ fontSize: getClamp(56, 100) }">
        Don't let your metabolism rate stop you from achieving your ideal body shape
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import ImageSlider from '@/components/common/image-slider.vue'

import SkuOralSemaglutideImage from '@/assets/image/sku/60x60/Oral-Semaglutide.webp'
import SkuInjectableSemaglutideImage from '@/assets/image/sku/60x60/Semaglutide-Injection.webp'
// import SkuOralTirzepatideImage from '@/assets/image/sku/60x60/Oral-Tirzepatide.webp'

import image1 from '@/assets/image/contrast/os/410×490/01.webp'
import image2 from '@/assets/image/contrast/os/410×490/02.webp'
import image3 from '@/assets/image/contrast/si/410×490/01.webp'
import image4 from '@/assets/image/contrast/si/410×490/02.webp'
// import image5 from '@/assets/image/contrast/ot/410×490/01.webp'
// import image6 from '@/assets/image/contrast/ot/410×490/02.webp'
import VerifiedSvg from '@/assets/svg/verified.svg'
import { getClamp } from '@/utils/index'
import { useRouter } from 'vue-router'

const router = useRouter()
</script>
<style scoped>
.section-3-container {
  padding-top: calc(
    clamp(5.313rem, 5.202rem + 0.47vw, 5.625rem) +
      clamp(-1rem, -3.11rem + 5.48vw, clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem))
  );
  padding-bottom: clamp(2.875rem, 2.127rem + 3.19vw, 5rem);
}
.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
}
.marquee-content {
  opacity: 0.2;
  color: #4f6f52;
  font-family: 'San Francisco Display';
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  display: inline-block;
  padding-left: 100%;
  animation: marquee 50s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.drug-container {
  width: clamp(21.563rem, 20.132rem + 6.1vw, 25.625rem);
}
.drug-box {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 0.625rem;
  margin-right: 0.62rem;
  overflow: hidden;
}

.start-button {
  font-size: clamp(0.875rem, 0.825rem + 0.25vw, 1.125rem);
  width: clamp(7.5rem, 7.1125rem + 1.9375vw, 9.4375rem);
  min-width: 7.5rem;
  height: 3.125rem;
  color: #fff;
  font-family: 'San Francisco Display';
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.drug-name {
  color: #333;
  font-family: 'San Francisco Display';
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  display: block;
  width: clamp(7rem, 6.675rem + 1.625vw, 8.625rem);
}
.drug-title {
  color: #333;
  font-family: 'San Francisco Display';
  font-size: clamp(1.625rem, 1.55rem + 0.375vw, 2rem);
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: capitalize;
  margin-top: clamp(1.25rem, 1.124rem + 0.6299999999999999vw, 1.88rem);
  margin-bottom: 1.25rem;
}
.drug-desc {
  color: #666;
  font-family: 'San Francisco Display';
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.625rem; /* 130% */
  text-transform: capitalize;
}
.desc-box {
  border: 1px solid #e8dfca;
}
.tip-box {
  font-family: 'Gloock';
}
@media (min-width: 1280px) {
  .desc-box {
    padding: 1.56rem 1.25rem 1.06rem 1.25rem;
  }
  .drug-title {
    margin-top: 1.88rem;
    margin-bottom: 1.25rem;
  }
  .drug-desc {
    height: 15.8125rem;
  }
  .tip-box {
    font-size: 1rem;
  }
}
@media (max-width: 1280px) {
  .section-3-container {
    z-index: 12;
    padding-top: calc(clamp(5.313rem, 5.202rem + 0.47vw, 5.625rem));
  }
  .desc-box {
    padding: 1.25rem 0.94rem 1.88rem 0.94rem;
  }
  .drug-desc {
    color: #222;
    font-size: 0.875rem;
  }
  .tip-box {
    padding: 0.81rem 2.31rem;
    font-size: 0.875rem;
  }
}
.green {
  color: #1a4d2e;
}
.verified {
  color: #9d6c46;
  font-family: Gloock;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  /** Slight adjust for visual */
  padding-top: 1px;
}
</style>
