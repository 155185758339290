<template>
  <div class="page-6th">
    <div
      class="m-auto px-4 w-full sm:max-w-[540px-to-767px] md:max-w-[720px-to-991px] lg:max-w-[960px-to-1279px] min-xl:max-w-[1232px] page-6th-container"
    >
      <div class="section1">
        <div class="desc-content">
          <div class="desc-content-box">
            <h1 class="section-title">
              {{ isMobile() ? 'Supercharge your  ' : 'Proven results,' }}
              <span class="dark-green" v-if="isMobile()"> weight loss goals </span>
              <div v-else class="dark-green">clinically tested</div>
            </h1>
            <div class="title2">
              Our medication is delivered from a state licensed pharmacy in our network, right to
              your door when you need it.
            </div>
            <p class="text">
              Our pharmacies perform third party testing through FDA and DEA registered labs to run
              quality control checks for every compounded lot prescribed for Thinly patients. They
              test four key characteristics that are associated with quality and safety.
            </p>
            <div class="desc2-content1">
              <h3>Clean, simple,<span class="green">and effective</span></h3>
              <p class="text">
                Your health and well-being are our absolute top priority. We make every effort to
                ensure you get the best treatment every time, starting from day one.
              </p>
            </div>
          </div>
        </div>
        <div class="test-content">
          <PassedBox
            v-for="(item, index) in testTitleList"
            class="relative"
            :key="item.title"
            :title="item.title"
            :init-expanded="item.initExpanded"
            :style="{
              zIndex: index + 100,
            }"
          >
            <template #collapsed>
              <div class="flex">
                <div class="test-box-process">
                  <div class="label">Date</div>
                  <div class="process-box"></div>
                </div>
                <div class="test-box-process">
                  <div class="label">Lab</div>
                  <div class="process-box"></div>
                </div>
              </div>
            </template>
            <template #expanded>
              <p v-html="item.content"></p>
            </template>
          </PassedBox>
        </div>
        <div class="desc2-content2">
          <h3 class="section-title">
            Supercharge your  <span class="dark-green">weight loss goals</span>
          </h3>
          <p class="text">
            Your health and well-being are our absolute top priority. We make every effort to ensure
            you get the best treatment every time, starting from day one.
          </p>
        </div>
      </div>
    </div>
    <div
      class="logo-box-list flex m-auto px-4 w-full sm:max-w-[540px-to-767px] md:max-w-[720px-to-991px] lg:max-w-[960px-to-1279px] min-xl:max-w-[1232px] page-6th-container"
    >
      <div class="logo-box-content" v-for="(item, index) in logoList" :key="index">
        <div class="logo-box">
          <component :is="item.logo" />
        </div>
        <div class="logo-desc">
          <p>{{ item.desc.split(' ')[0] }}</p>
          <p>{{ item.desc.split(' ')[1] }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  CrueltyFree,
  ECOFriendly,
  ParabenFree,
  SiliconeFree,
  SulfateFreel,
  GlutenFree,
} from '@/assets/svg'
import { isMobile } from '@/utils'
import PassedBox from '../common/passed-box.vue'
const logoList = [
  {
    desc: 'Cruelty Free',
    logo: CrueltyFree,
  },
  {
    desc: 'ECO Friendly',
    logo: ECOFriendly,
  },
  {
    desc: 'Paraben Free',
    logo: ParabenFree,
  },
  {
    desc: 'Silicone Free',
    logo: SiliconeFree,
  },
  {
    desc: 'Sulfate Freel',
    logo: SulfateFreel,
  },
  {
    desc: 'Gluten Free',
    logo: GlutenFree,
  },
]
const testTitleList = [
  {
    title: 'Potency test',
    content: `This test is performed every 3 to 6 months. It confirms that the medication has plus or minus 10% of the appropriate concentration of the active ingredient.`,
  },
  {
    title: 'Sterility test',
    content: `This test ensures the medication is free from any contaminants, including bacteria or other pathogens. Every batch is tested and must meet the requirements of
  <a href="https://www.usp.org/compounding/general-chapter-797" style="text-decoration: underline">USP 797.1</a>.`,
  },
  {
    title: 'PH Test',
    content: `This test assesses the acid/base balance to ensure minimal irritation upon injection.`,
  },
  {
    title: 'Endotoxicity',
    initExpanded: true,
    content: `This test, along with sterility testing, ensures the medication is free of harmful toxins. Every batch is tested for bacterial endotoxins, which may not exceed the threshold limits defined in
    <a href="https://www.usp.org/harmonization-standards/pdg/general-methods/bacterial-endotoxins" style="text-decoration: underline">USP 85.</a>`,
  },
]
</script>
<style scoped>
@media (max-width: 768px) {
  .logo-box-list {
    max-width: 100%;
    overflow: hidden;
    overflow-x: scroll;
  }
}

.page-6th {
  border-radius: 0rem 0rem clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem)
    clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem);
  background: #f5efe6;
}
.green {
  color: #4f6f52;
}
.desc-content {
  height: fit-content;
  overflow: hidden;
  position: relative;
  flex: 1;
  margin-right: 2.88rem;
}
.test-content {
  break-inside: avoid;
}
.logo-box {
  background-color: #fff;
  border-radius: 50%;
  width: 7.25rem;
  height: 7.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-box .logo {
  width: 7.25rem;
  height: 7.25rem;
}
.test-box-process .label {
  color: #999;
  font-family: 'San Francisco Display';
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.test-box-process {
  display: flex;
  align-items: center;
  margin-right: 2.5rem;
}
.process-box {
  width: 5rem;
  height: 0.75rem;
  flex-shrink: 0;
  border-radius: 0.375rem;
  background: #d9d9d9;
  margin-left: 0.62rem;
}
.test-header {
  justify-content: space-between;
}
.add-icon {
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
  background-color: #999;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.62rem;
}
.test-content p {
  color: #999;
  font-family: 'San Francisco Display';
  font-size: clamp(0.875rem, 0.831rem + 0.19vw, 1rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
h1 {
  color: #333;
  font-size: 2.5rem;
  line-height: normal;
  text-transform: capitalize;
  text-align: left;
  margin-top: 6.25rem;
  font-weight: 400;
  font-family: Gloock;
}
.text {
  position: relative;
  z-index: 10;
  color: #333;
  font-family: 'San Francisco Display';
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.875rem; /* 166.667% */
  text-transform: capitalize;
}
.title2 {
  position: relative;
  z-index: 10;
  max-width: clamp(21.438rem, 17.036rem + 18.78vw, 33.938rem);
  margin-top: 2.5rem;
  margin-bottom: 3.12rem;
  color: #333;
  font-family: 'San Francisco Display';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.logo-box-content {
  width: 7.25rem;
  text-align: center;
  margin-top: 2.5rem;
  margin-right: 3.12rem;
}
.logo-desc {
  color: #333;
  font-family: 'San Francisco Display';
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem; /* 100% */
  text-transform: capitalize;
  margin-top: 1.25rem;
}

@media (min-width: 991px) {
  .page-6th {
    padding-top: calc(3.5rem + clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem));
    padding-bottom: 7.44rem;
  }
  .section1 {
    display: flex;
  }
  h3 {
    color: #333;
    font-family: 'San Francisco Display';
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 1.25rem;
  }
  .desc2-content1 {
    display: block;
    margin-top: 6.25rem;
  }
  .desc2-content2 {
    display: none;
  }
  .desc-content-box {
    max-width: 37.5rem;
  }
}
@media (max-width: 991px) {
  .page-6th {
    padding: 2.56rem 0;
    padding-top: calc(2.56rem + clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem));
    display: block;
  }
  .section1 {
    display: block;
  }
  h1 {
    color: #222;
    font-size: 1.5rem;
    line-height: 2rem; /* 133.333% */
    margin-bottom: 2.5rem;
    margin-top: 2.56rem;
    text-align: left;
  }
  .title2 {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
  }
  .text {
    font-size: 1rem;
    margin-top: 0.63rem;
    margin-bottom: 1.25rem;
  }
  .text {
    font-size: 1rem;
  }
  .test-content {
    margin-bottom: 3rem;
    margin-right: 1.3125rem;
  }
  .desc2-content1 {
    display: none;
  }
  .desc2-content2 {
    display: block;
    color: #222;
    font-family: Gloock;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    text-transform: capitalize;
  }
  h3 {
    color: #222;
    font-family: Gloock;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
  }
  .desc-content2 .text {
    color: #333;
    font-size: 1rem;
    margin-bottom: 1.25rem;
  }
  .logo-box-content {
    margin-right: 0.94rem;
    margin-top: 0;
  }
  .logo-box {
    width: 5.625rem;
    height: 5.625rem;
    margin-bottom: 0.62rem;
  }
  .logo-box .logo {
    width: 3.39438rem;
    height: 3.39438rem;
  }
  .logo-desc {
    font-size: 1rem;
  }

  h1 {
    margin-top: 0;
  }
  .desc-content {
    margin-right: 0px;
  }
  .test-content {
    margin-right: 0;
  }
}
</style>
