<template>
  <section
    class="bg-default"
    style="
      padding-top: calc(
        clamp(2.5rem, 1.3996478873239437rem + 4.694835680751173vw, 5.625rem) +
          clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem)
      );
      padding-bottom: clamp(2.5rem, 1.3996478873239437rem + 4.694835680751173vw, 5.625rem);
      border-radius: 0rem 0rem clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem)
        clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem);
    "
  >
    <div
      class="m-auto w-full px-4 sm:max-w-[540px-to-767px] md:max-w-[720px-to-991px] lg:max-w-[960px-to-1279px] min-xl:max-w-[1232px]"
    >
      <div class="section-title" :style="{ marginBottom: `calc(${getClamp(40, 60)} - 20px)` }">
        Experience an average weight loss of&nbsp; <br class="break" />
        <span class="dark-green">14.9% within a year</span>
        with a GLP-1*
      </div>
      <div class="flex max-md:flex-col flex-row-reverse max-md:items-end justify-between">
        <div
          class="relative w-full flex flex-col items-end max-md:items-start max-md:mt-6 max-md:mb-10"
        >
          <img
            loading="lazy"
            draggable="false"
            alt="weight loss image"
            src="@/assets/image/weight-loss.webp"
            :style="{ width: getClamp(286.75, 442.156), height: 'fit-content', borderRadius: '1.25rem'}"
          />
          <div
            class="absolute right-[160px] bottom-[60px] max-md:right-0 max-md:bottom-[-60px] flex flex-col justify-center rounded-3xl bg-white"
            :style="{
              width: getClamp(320, 440),
              height: getClamp(170, 250),
              paddingLeft: getClamp(20, 25),
              paddingRight: getClamp(20, 25),
            }"
          >
            <div class="w-full flex items-center justify-between">
              <p class="tip text-light-green" :style="{ fontSize: getClamp(16, 20) }">
                Choose your<br />starting weight:
              </p>
              <div class="flex-0">
                <span
                  class="tip-result leading-none text-light-green"
                  :style="{ fontSize: getClamp(32, 40) }"
                >
                  {{ weight }} <span class="tip-result-affix">lbs</span>
                </span>
              </div>
            </div>
            <div class="relative flex w-full items-center">
              <input
                type="range"
                min="120"
                max="300"
                class="lose-weight-calc h-2 w-full appearance-none rounded-full outline-none max-xs:h-[6px]"
                :value="weight"
                @input="handleAdjustWeight($event)"
                :style="{
                  marginTop: getClamp(20, 30),
                  marginBottom: getClamp(20, 30),
                  background: `linear-gradient(to right, rgba(26, 77, 46, 1), rgba(245, 239, 230, 1))`,
                }"
              />
            </div>
            <div class="w-full flex items-center justify-between">
              <p class="tip-bold text-green" :style="{ fontSize: getClamp(18, 24) }">
                You can<br />lose up to:
              </p>
              <div class="flex-0">
                <span class="tip-bold-result text-green" :style="{ fontSize: getClamp(50, 60) }">
                  {{ loseWeight }} <span class="tip-result-affix">lbs</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-10">
          <p class="sub-title1">
            Supercharge your
            <span class="high-light">weight loss goals</span>
          </p>
          <div class="section-title sub-title2" :style="{ marginBottom: getClamp(20, 50) }">
            Supercharge your
            <span class="dark-green"> weight loss goals </span>
          </div>
          <div class="flex flex-col">
            <div class="box flex items-center max-md:bg-white max-md:rounded-3xl">
              <img
                loading="lazy"
                draggable="false"
                src="@/assets/image/section-4/icon-1.webp"
                class="flex-0"
                alt="icon: Enhance your physical health"
                :style="{ width: getClamp(80, 100), height: getClamp(80, 100) }"
              />
              <div class="goal-box pl-5 max-md:pl-0 max-md:pr-4">
                <p class="goal-box-title">Enhance your physical health</p>
                <p class="goal-box-content">
                  Losing just 10% of your weight can lower your blood pressure, give you more
                  energy, and may help you sleep better.<b>**</b>
                </p>
              </div>
            </div>
            <div class="box flex items-center max-md:mt-4 max-md:bg-white max-md:rounded-3xl">
              <img
                loading="lazy"
                draggable="false"
                src="@/assets/image/section-4/icon-2.webp"
                class="flex-0"
                alt="icon: Boost your confidence"
                :style="{ width: getClamp(80, 100), height: getClamp(80, 100) }"
              />
              <div class="goal-box pl-5 max-md:pl-0 max-md:pr-2">
                <p class="goal-box-title">Boost your confidence</p>
                <p class="goal-box-content">
                  Achieving a healthy weight can positively impact your overall well-being.<b>**</b>
                </p>
              </div>
            </div>
            <div class="box flex items-center max-md:mt-4 max-md:bg-white max-md:rounded-3xl">
              <img
                loading="lazy"
                draggable="false"
                src="@/assets/image/section-4/icon-3.webp"
                class="flex-0"
                alt="icon: Revitalize your emotional health"
                :style="{ width: getClamp(80, 100), height: getClamp(80, 100) }"
              />
              <div class="goal-box pl-5 max-md:pl-0 max-md:pr-4">
                <p class="goal-box-title">Revitalize your emotional health</p>
                <p class="goal-box-content">
                  Weight loss may support mental health and improve mood.<b>**</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="after-68-weeks-container"
        :style="{
          paddingTop: getClamp(30, 50),
          paddingBottom: getClamp(50, 90),
        }"
      >
        <p class="after-68-weeks-participants-a">
          <b class="b">*</b>
          <span class="b"
            >After 68 weeks, participants administering once-weekly semaglutide treatment
            experienced an average of 15% reduction in body weight. The mean change in body weight
            from baseline to week 68 was −14.9% in the semaglutide group as compared with −2.4% with
            placebo.
          </span>
          <b>(</b>
          <a class="source" href="https://pubmed.ncbi.nlm.nih.gov/33567185/" target="_blank">
            <b>
              <span class="source1">Source</span>
            </b>
          </a>
          <b>)</b>
        </p>
        <p class="after-68-weeks-participants-a">
          <span class="b">&nbsp;</span>
        </p>
        <p class="after-68-weeks-participants-a">
          <b class="b">** </b>
          <span class="b"
            >Obstructive Sleep Apnea: 10% or more should be the goal to impact clinical symptoms.
            With the intensive lifestyle intervention (ILI), average weight loss at one year
            was 10.8 kg (23.76 lb) and these patients at 1 year had a remission of OSA 3 times more
            common in patients who lost 23.76lb with ILI vs 0.6 kg (1.32 lb) in the diabetes support
            and education (DSE) group.
          </span>
          <b>(</b>
          <a class="source" href="https://pubmed.ncbi.nlm.nih.gov/23633746/" target="_blank">
            <b>
              <span class="source1">Source</span>
            </b>
          </a>
          <b>)</b>
        </p>
        <p class="after-68-weeks-participants-a">
          <span class="b">&nbsp;</span>
        </p>
        <p class="after-68-weeks-participants-a">
          <span class="b"
            >Cardiovascular Disease Risk Factors: Improvement in triglycerides and systolic blood
            pressure begins with 2-5% weight loss. For diastolic blood pressure and HDL cholesterol,
            improvement begins at 5-10% weight loss.
          </span>
          <b>(</b>
          <a
            class="source"
            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5497590/"
            target="_blank"
          >
            <b>
              <span class="source1">Source</span>
            </b>
          </a>
          <b>)</b>
        </p>
        <p class="after-68-weeks-participants-a">
          <span class="b">&nbsp;</span>
        </p>
        <p class="after-68-weeks-participants-a">
          <span class="b"
            >Emotional Health & Confidence: Pursuing dietary interventions for obese patients with
            comorbid depression could have the additional benefit of relieving some of their
            depressive symptoms as well as improving their metabolic profile and cardiovascular
            risk.
          </span>
          <b>(</b>
          <a class="source" href="https://www.mdpi.com/2075-4426/11/3/176" target="_blank">
            <b>
              <span class="source1">Source</span>
            </b>
          </a>
          <b>) </b>
        </p>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { getClamp } from '@/utils/index'

const weight = ref(209)
const loseWeight = computed(() => Math.floor(weight.value * 0.15) + 1)

const handleAdjustWeight = (e: Event) => {
  if (!e.target) return
  if ('value' in e.target) {
    weight.value = e.target.value as number
  }
}
</script>
<style scoped>
.b {
  font-family: 'San Francisco Display';
}
.source1 {
  text-decoration: underline;
}
.source {
  color: inherit;
}
.after-68-weeks-participants-a {
  margin: 0;
}
.after-68-weeks-container {
  width: clamp(21.562rem, 1.8rem + 84.04vw, 77.5rem);
  position: relative;
  font-size: 0.75rem;
  text-transform: capitalize;
  color: #666;
  text-align: left;
  display: inline-block;
  line-height: normal;
  font-weight: 400;
  height: 140px;
  font-family: 'San Francisco Display';
}
.goal-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: calc(10 / 16 * 1rem);
  width: 21.5625rem;
}
.goal-box-title {
  width: clamp(14.75rem, 12.241197183098592rem + 10.704225352112676vw, 22rem);
  color: #333;
  font-family: 'San Francisco Display';
  font-size: clamp(1.125rem, 0.993rem + 0.56vw, 1.5rem);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.goal-box-content {
  color: #333;
  font-family: 'San Francisco Display';
  font-size: clamp(0.875rem, 0.831rem + 0.19vw, 1rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.tip,
.tip-bold,
.tip-result,
.tip-bold-result {
  font-family: 'San Francisco Display';
  font-style: normal;
  line-height: normal;
  text-transform: capitalize;
}
.tip-bold {
  font-weight: 900;
}
.tip-result,
.tip-bold-result {
  text-transform: lowercase;
  display: flex;
  font-weight: 700;
  vertical-align: middle;
  align-items: center;
  gap: calc(6 / 16 * 1rem);
}
.tip-result-affix {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: lowercase;
}
/* 小于991px */
@media (max-width: 991px) {
  .sub-title2 {
    display: block;
    margin-top: calc(40px - 1.25rem);
    width: 19.0625rem;
  }
  .sub-title1 {
    display: none;
  }
  .goal-box {
    height: 9.375rem;
  }
  .break {
    display: block;
  }
}
/* 大于991px */
@media (min-width: 992px) {
  .sub-title2 {
    display: none;
  }
  .sub-title1 {
    color: #333;
    font-family: 'San Francisco Display';
    font-size: calc(30 / 16 * 1rem);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 2.62rem;
    width: max-content;
  }
  .sub-title1 .high-light {
    color: #4f6f52;
  }
  div.box:not(:first-child) {
    padding-top: 2.5rem;
  }
  div.box:first-child {
    padding-top: 2.62rem;
  }
  .break {
    display: none;
  }
  .px-4.min-xl\:max-w-\[1232px\] {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
