<template>
  <div class="page-7th" id="page-7th" style="background-color: #fff">
    <div
      class="m-auto px-4 w-full sm:max-w-[540px-to-767px] md:max-w-[720px-to-991px] lg:max-w-[960px-to-1279px] min-xl:max-w-[1232px] page-7th-container"
    >
      <div class="left">
        <div ref="leftRef" :class="{ 'left-fixed': isLeftFixed }">
          <div class="section-title">
            Begin your <span class="dark-green"> weight loss journey</span> with thinly
          </div>
          <div class="text">
            By addressing the root causes of weight and blood sugar issues, we empower you to feel
            and perform better.
          </div>
        </div>
      </div>
      <div class="right">
        <div class="journey-content" v-for="(item, index) in journeyList" :key="index">
          <div class="dot">
            <Dot />
          </div>
          <div class="journey-box">
            <div class="journey-box-title">{{ item.title }}</div>
            <div class="journey-box-text">
              {{ item.text }}
            </div>
            <div class="journey-box-img">
              <img
                :alt="`image for ${item.title}`"
                loading="lazy"
                draggable="false"
                :src="item.img"
                style="
                  object-fit: cover;
                  width: clamp(17.1875rem, 14.43661971830986rem + 11.737089201877934vw, 25rem);
                  height: fit-content;
                  max-height: 14.375rem;
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, onBeforeUnmount, ref } from 'vue'
import { Dot } from '@/assets/svg'
import { isMobile } from '@/utils'
import journeyImg1 from '@/assets/image/journey/consult001.webp'
import journeyImg2 from '@/assets/image/journey/quiz002.webp'
import journeyImg3 from '@/assets/image/journey/receive003.webp'
import journeyImg4 from '@/assets/image/journey/back004.webp'
const leftRef = ref()
const isLeftFixed = ref(false)
onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})
onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})
const handleScroll = () => {
  const page7th = document.getElementById('page-7th')

  if (page7th && !isMobile()) {
    const rect = page7th.getBoundingClientRect()
    isLeftFixed.value = rect.top <= 0 && rect.top > (rect.height - 200) * -1 ? true : false
  }
}
const journeyList = [
  {
    title: 'Take a 3-minute questionnaire',
    text: 'Submit your health history and weight loss goals to get started. A licensed medical provider will review your history and personalize an approach to meet your needs. In some cases, a metabolic lab test is needed before proceeding with treatment.',
    img: journeyImg1,
  },
  {
    title: 'Consult with a medical provider',
    text: 'Experience a quick and easy online consultation from the comfort of your own home. Your medical provider will assess your weight loss goals, guide you through a personalized weight loss plan, and determine a prescription treatment tailored for you.',
    img: journeyImg2,
  },
  {
    title: 'Receive your weight loss medications',
    text: 'Experience a quick and easy online consultation from the comfort of your own home. Your medical provider will assess your weight loss goals, guide you through a personalized weight loss plan, and determine a prescription treatment tailored for you.',
    img: journeyImg3,
  },
  {
    title: 'Receive ongoing care and support',
    text: 'To monitor your progress effectively, regular check-ins will be scheduled with on-demand medical support available to address your concerns.',
    img: journeyImg4,
  },
]
</script>
<style scoped>
.page-7th {
  border-radius: 0 0 clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem)
    clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem);
}
.journey-box-title-desc {
  color: #333;
  font-family: 'San Francisco Display';
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem;
  text-transform: capitalize;
}
.green {
  color: #1a4d2e;
}
.left .title {
  color: #333;

  /* 西文衬线 标题 */
  font-family: Gloock;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  width: 28.8125rem;
}
.text {
  color: #333;
  font-family: 'San Francisco Display';
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.875rem; /* 166.667% */
  text-transform: capitalize;
  margin-top: 2.25rem;
  width: clamp(21.4375rem, 18.796654929577464rem + 11.267605633802818vw, 28.9375rem);
}
.journey-box {
  height: 100%;
}
.journey-content {
  display: flex;
  position: relative;
}
.dot {
  margin-right: clamp(3.1875rem, 1.7130281690140847rem + 6.291079812206572vw, 7.375rem);
  width: fit-content;
  width: 2.125rem;
  height: 2.125rem;
  z-index: 2;
}
.right {
  position: relative;
}
.journey-content:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 20px;
  left: 1.0625rem;
  transform: translateX(-50%);
  width: 0.1875rem;
  height: 100%;
  background: rgba(79, 111, 82, 0.5);
  z-index: 1;
  flex-shrink: 0;
}

.journey-content:last-child::after {
  content: '';
  position: absolute;
  top: 20px;
  left: 1.0625rem;
  transform: translateX(-50%);
  width: 0.1875rem;
  background-image: url('@/assets/image/journey/journey-line.webp');
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  background-position: 0 1rem;
  height: 17.5rem;
  z-index: 1;
}

.journey-box-title {
  color: #333;
  font-family: 'San Francisco Display';
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 0.62rem;
}
.journey-box-text {
  color: #666;
  font-family: 'San Francisco Display';
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
  text-transform: capitalize;
}
.journey-box-img img {
  border-radius: 10px;
  width: clamp(17.1875rem, 14.43661971830986rem + 11.737089201877934vw, 25rem);
  max-height: 14.375rem;
  flex-shrink: 0;
  margin-top: 0.62rem;
  margin-bottom: 3.12rem;
}
@media (min-width: 991px) {
  .page-7th-container {
    position: relative;
    padding-top: calc(
      clamp(2.5rem, 1.3996478873239437rem + 4.694835680751173vw, 5.625rem) +
        clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem)
    );
    padding-bottom: clamp(2.5rem, 1.3996478873239437rem + 4.694835680751173vw, 5.625rem);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0;
  }
  .left-fixed {
    position: sticky;
    top: 2rem;
    left: 6.38rem;
    z-index: 1000;
    width: 28.9375rem;
  }
}
@media (max-width: 991px) {
  .page-7th-container {
    padding: 2.5rem 0.94rem 3.31rem 0.94rem;
    padding-top: calc(
      clamp(2.5rem, 1.3996478873239437rem + 4.694835680751173vw, 5.625rem) +
        clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem)
    );
    padding-bottom: clamp(2.5rem, 1.3996478873239437rem + 4.694835680751173vw, 5.625rem);
    /* display: flex;
    flex-direction: column; */
  }
  .left .title {
    font-size: 1.5rem;
    width: 100%;
    word-break: break-word;
  }
  .left {
    margin-bottom: 4.38rem;
  }
  .left .text {
    margin-top: 0.63rem;
    line-height: 1.5rem;
  }
  .right {
    margin-top: 4.37rem;
  }
  .dot {
    margin-right: 2.25rem;
  }

  .journey-box-text {
    margin-top: 0.5rem;
  }
  .journey-box-img {
    margin-top: 0.62rem;
  }
  .journey-box-img img {
    margin-bottom: 1.25rem;
  }
  .journey-box-title {
    margin-bottom: 0.62rem;
  }
}
</style>
