<template>
  <div
    style="
      border-radius: 0 0 clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem)
        clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem);
      background-color: #fff;
      padding-top: calc(
        clamp(2.5rem, 1.3996478873239437rem + 4.694835680751173vw, 5.625rem) +
          clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem)
      );
      padding-bottom: clamp(2.5rem, 1.18rem + 5.63vw, 6.25rem);
    "
  >
    <div
      class="m-auto w-full px-4 sm:max-w-[540px-to-767px] md:max-w-[720px-to-991px] lg:max-w-[960px-to-1279px] min-xl:max-w-[1232px]"
    >
      <h2
        class="section-title mt-6 max-xs:mt-4"
        style="
          margin: 0 auto;
          text-align: center;
          margin-bottom: clamp(1.75rem, 1.045774647887324rem + 3.004694835680751vw, 3.75rem);
        "
      >
        <span>Our Members <span class="dark-green">Love Us</span></span>
      </h2>
    </div>
    <div>
      <div>
        <div id="section-9th" class="swiper">
          <div class="swiper-wrapper">
            <div
              v-for="review in reviewList"
              :key="review.name"
              class="swiper-slide lazy-load"
              :data-bg-src="review.backgroundSource"
              style="background: lightgray; background-size: cover"
            >
              <div
                class="slide-item flex h-full w-full flex-col items-stretch justify-between gap-6 rounded-3xl text-primary active:cursor-grab"
              >
                <div
                  class="flex items-start justify-between w-full gap-6 h-full max-sm:h-full max-sm:flex-col"
                >
                  <div class="flex flex-col items-start justify-start gap-1 h-full w-full">
                    <h4 style="padding-top: 28px">
                      {{ review.name }}
                    </h4>
                    <p style="padding-bottom: 28px">
                      {{ review.career }}
                    </p>
                    <div class="review-content flex items-center gap-1 rounded-full w-full">
                      <span>{{ review.content }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="description px-4">
          thinly Weight Loss Program Members taking GLP-1 medications were paid for their
          testimonials.
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Swiper from 'swiper'
import 'swiper/css';
import '@/assets/extra-swiper.css'

import { onMounted } from 'vue'
import { isMobile } from '@/utils/index'

import JamieSrc from '@/assets/image/our-members-love-us/jamie.webp'
import MandySrc from '@/assets/image/our-members-love-us/mandy.webp'
import VictoriaSrc from '@/assets/image/our-members-love-us/victoria.webp'

const reviewList = [
  {
    name: 'Jamie',
    career: 'Thinly Member',
    backgroundSource: JamieSrc,
    content:
      '"It gave me confidence and it gave me the boost I needed to get out of this cycle and to start developing healthier habits."',
  },
  {
    name: 'mandy',
    career: 'Thinly Member',
    backgroundSource: MandySrc,
    content:
      '"I finally did it. I am finally down over 20 lbs on Thinly. I am 41 years old, I have 3 kids, and I have Polycystic Ovarian Syndrome."',
  },
  {
    name: 'Victoria',
    career: 'Thinly Member',
    backgroundSource: VictoriaSrc,
    content:
      '"To look in the mirror and see myself smaller, to try on smaller clothes has been such a victory for me. I feel very accomplished."',
  },
]

// 初始调用
onMounted(() => {
  new Swiper('#section-9th', {
    freeMode: true,
    observer: true,
    slidesPerView: 'auto',
    spaceBetween: isMobile() ? 16 : 32,
  })

  // 懒加载
  const lazyLoadList = document.querySelectorAll('.lazy-load')
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const slide = entry.target as HTMLElement
        const bgSrc = slide.getAttribute('data-bg-src')
        if (bgSrc) {
          slide.style.backgroundImage = `url(${bgSrc})`
        }
        slide.classList.remove('lazy-load')
        observer.unobserve(slide)
      }
    })
  })
  lazyLoadList.forEach((slide) => {
    observer.observe(slide)
  })
})
</script>
<style lang="css" scoped>
.swiper-slide {
  border: none;
  width: clamp(18.125rem, 15.426136363636363rem + 9.595959595959595vw, 23.0625rem);
  height: clamp(25.892875rem, 22.037363636363636rem + 13.708484848484847vw, 34.375rem);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
}
.slide-item {
  border: none;
  color: #fff;
  font-family: 'San Francisco Display';
  text-transform: capitalize;
  user-select: none;
  color: #fff;
  padding-left: 1.25rem;
  padding-right: clamp(1.25rem, 0.81rem + 1.88vw, 2.5rem);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 24.5%,
    rgba(0, 0, 0, 0) 50.5%,
    rgba(0, 0, 0, 0) 75%,
    rgba(0, 0, 0, 0.5) 100%
  );
}
.slide-item h4 {
  text-align: center;
  font-family: 'San Francisco Display';
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: capitalize;
}
.slide-content {
  background-color: #eee3d2;
  color: #666;
  text-align: center;
  font-family: 'San Francisco Display';
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
  text-transform: capitalize;
}

.review-content {
  flex-direction: column-reverse;
  flex: 1;
  padding-bottom: clamp(1.268125rem, 1.2084859154929577rem + 0.2544600938967137vw, 1.4375rem);
}

.review-content span {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.description {
  height: calc(-1 * clamp(-2.25rem, -2.646rem + 1.69vw, -1.125rem));
  color: #666;
  text-align: center;
  font-family: 'San Francisco Display';
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-transform: capitalize;
  margin-top: clamp(0.919rem, 0.583rem + 1.44vw, 1.875rem);
}
</style>
