<template>
  <div
    class="bg-default"
    style="
      border-radius: 0 0 clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem)
        clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem);
      padding-top: calc(
        clamp(2.5rem, 1.3996478873239437rem + 4.694835680751173vw, 5.625rem) +
          clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem)
      );
      padding-bottom: clamp(2.5rem, 1.3996478873239437rem + 4.694835680751173vw, 5.625rem);
    "
  >
    <div
      class="m-auto w-full px-4 sm:max-w-[540px-to-767px] md:max-w-[720px-to-991px] lg:max-w-[960px-to-1279px] min-xl:max-w-[1232px]"
    >
      <h2
        class="section-title mt-6 font-gradual font-bold text-primary max-xs:mt-4"
        style="margin-bottom: clamp(16px, 7.71271px + 2.20994vw, 36px)"
      >
        Do you have questions? <span class="dark-green">We have answers.</span>
      </h2>
      <div class="[*]:outline-none flex flex-col">
        <button
          v-for="tab in tabList"
          :key="tab.index"
          :id="`button-QnA${tab.index}`"
          :aria-expanded="tab.defaultExpanded"
          class="flex flex-col border-b"
          :aria-controls="`content-QnA${tab.index}`"
          @click="handleClick('QnA' + tab.index)"
        >
          <div
            class="w-full"
            style="
              padding-top: clamp(16px, 11.0276px + 1.32597vw, 28px);
              padding-bottom: clamp(16px, 11.0276px + 1.32597vw, 28px);
            "
          >
            <div class="flex w-full items-center justify-between gap-6">
              <p class="section-content-question text-left font-medium text-primary">
                {{ tab.question }}
              </p>
              <div
                class="relative top-[2px] flex h-6 w-full max-w-6 items-center justify-center max-xs:h-[18px] max-xs:max-w-[18px]"
              >
                <span class="absolute w-full rounded-full" style="margin-right: 1em">
                  <DropdownSvg
                    :id="`icon-QnA${tab.index}`"
                    class="transition-transform duration-300 -rotate-0"
                    :style="{ scale: iconScale }"
                  />
                </span>
              </div>
            </div>
            <div
              :id="`content-QnA${tab.index}`"
              :aria-labelledby="`button-QnA${tab.index}`"
              style="
                max-height: 0px;
                transition-property: max-height;
                overflow: hidden;
                transition-duration: 300ms;
                transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
              "
            >
              <div
                class="section-content-answer m-0 pt-4 text-left font-medium text-primary transition-opacity duration-500 max-xs:pt-3 [&amp;>*]:m-0 [&amp;>*]:text-left"
              >
                <p v-for="answer in tab.answer" v-html="answer" :key="answer" class="mb-2" />
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from 'vue'
import DropdownSvg from '@/assets/svg/dropdown.svg'
type TTabItem = {
  index: number
  question: string
  answer: string[]
  defaultExpanded: 'true' | 'false'
}
const tabList = ref<TTabItem[]>([
  {
    index: 1,
    question: 'What happens after I sign up?',
    answer: [
      `Once you complete the health intake quiz, a licensed clinician will review your information. Depending on your health needs, you may either have a video call with a doctor or <b>receive a prescription directly within 24 hours.</b>`,
      `After your prescription is written, your medication will be processed, and you will receive shipping and tracking information within 2 business days. You'll receive a 4-week supply each month and have unlimited access to our patient care team and doctors for any questions. At the end of each month, you'll fill out a refill form to continue receiving your medication.`,
      `You'll also get text and email updates with tracking information for your shipment.`,
      `If you have questions about progress, side effects, or dosage, you can consult with a clinician at no extra cost through our patient portal.`,
    ],
    defaultExpanded: 'false',
  },
  {
    index: 2,
    question: "What's the difference between injectable vs sublingual?",
    answer: [
      `Injectable medications are administered directly into the body through a needle. This method provides very direct delivery into the bloodstream or tissues and is <b>administered once per week.</b>`,
      `Sublingual (oral) medications are taken by placing them under the tongue, where they dissolve and are absorbed through the mucous membranes into nearby blood vessels. It is <b>administered once per day.</b>`,
      `Studies have shown injectable to be more effective for weight loss.`,
    ],
    defaultExpanded: 'false',
  },
  {
    index: 3,
    question: `What is your cancellation and refund policy?`,
    answer: [
      `You have the flexibility to cancel your order and receive a full refund at any point before the doctor completes your prescription. After that, cancellations for future months are easy—just ensure you cancel before your next shipment is prepared.`,
      `Our service operates on a simple, month-to-month basis with no long-term commitments or hidden contracts, making it hassle-free to adjust your plan as needed.`,
      `Please note that once a prescription is approved and sent to the pharmacy for any month (including the first), refunds are no longer available. To cancel, simply call one of our care specialists at 1-(888) 270-1707, they will be more than happy to assist you.`,
    ],
    defaultExpanded: 'false',
  },
  {
    index: 4,
    question: `What if I'm pregnant and breastfeeding, or planning to get pregnant?`,
    answer: [
      `We do not recommend anyone planning to get pregnant or already pregnant to take GLP-1 medication. It's always important to discuss any and all of the medications you're taking or planning to take with your doctor to ensure safety. Consult with your doctor before starting or stopping any medication.`,
    ],
    defaultExpanded: 'false',
  },
  {
    index: 5,
    question: `Do you require insurance?`,
    answer: [`No, we do not require insurance and accept all major credit cards.`],
    defaultExpanded: 'false',
  },
  {
    index: 6,
    question: `Where are the doctors based and where is the medication coming from?`,
    answer: [
      `All doctors are based in the United States, where they are licensed and board certified. All medications come from US facilities that meet strict USP guidelines.`,
    ],
    defaultExpanded: 'false',
  },
  {
    index: 7,
    question: `Will I get charged if I don't qualify for medication?`,
    answer: [
      `You won't be charged if you're not eligible. Payment is processed only after you finish the pre-qualification quiz and choose your medication. If the doctor decides you don't qualify, a <b>full refund</b> will be issued within 24-48 hours. For video appointments, if you're deemed ineligible, there's no charge for the appointment, and you'll receive a complete refund.`,
    ],
    defaultExpanded: 'false',
  },
  {
    index: 8,
    question: `I have a previous prescription from another weight-loss company, can I start at a higher dosage?`,
    answer: [
      `If you already have a prescription from another weight loss company you will need to consult with our medical provider on the proper steps to switch over. In order to ensure your safety they will ask you a few questions about your current prescription and dosage.`,
    ],
    defaultExpanded: 'false',
  },
  {
    index: 9,
    question: `Does the price of the medications ever increase?`,
    answer: [
      `Your first month will start at $179 for Sublingual Semaglutide / $199 for Injectable Semaglutide. Afterwards your 2nd month will be $349 for Sublingual Semaglutide / $399 for injectable Semaglutide. These costs cover the price of medication and all ongoing care & support.`,
    ],
    defaultExpanded: 'false',
  },
  {
    index: 10,
    question: `What is the process of getting my monthly prescription after my first month?`,
    answer: [
      `Once you’ve finished your third dose for the month, you will be sent an email to refill your prescription. A clinician will assess your refill request before approving your prescription for the next month. If you need to address side effects or other concerns, you can also schedule an appointment through the portal.`,
    ],
    defaultExpanded: 'false',
  },
])

const getIconScale = () => {
  // 电脑1，手机0.6
  return window.innerWidth > 768 ? 1 : 0.6
}
const iconScale = ref(getIconScale())
const handleClick = (id: string) => {
  const button = document.querySelector(`#button-${id}`)
  if (!button) return
  const isExpanded = button.getAttribute('aria-expanded')
  button.setAttribute('aria-expanded', isExpanded === 'false' ? 'true' : 'false')
  const content: HTMLElement | null = document.querySelector(`#content-${id}`)
  if (!content) return
  content.style.maxHeight = isExpanded === 'false' ? content.scrollHeight + 'px' : '0px'
  const icon = document.querySelector(`#icon-${id}`)
  if (!icon) return
  if (isExpanded === 'false') {
    icon.classList.remove('rotate-0')
    icon.classList.add('rotate-180')
  } else {
    icon.classList.remove('rotate-180')
    icon.classList.add('rotate-0')
  }
}

const updateIconScale = () => {
  iconScale.value = getIconScale()
}

onMounted(() => {
  window.addEventListener('resize', updateIconScale)
})
onUnmounted(() => {
  window.removeEventListener('resize', updateIconScale)
})
</script>
<style lang="css" scoped>
button {
  border-color: #d9d9d9;
}
.section-content-question,
.section-content-answer {
  color: #222;
  font-family: 'San Francisco Display';
  font-style: normal;
  text-transform: capitalize;
}
.section-content-question {
  width: clamp(17.5rem, 4.956rem + 53.52vw, 53.125rem);
  font-size: clamp(1rem, 0.7727272727272727rem + 0.8080808080808081vw, 1.5rem);
  min-height: 3.625rem;
  display: flex;
  align-items: center;
  line-height: normal;
}
.section-content-answer {
  padding: 0.8em clamp(3.625rem, -3.021rem + 28.36vw, 22.5rem) 0
    clamp(0.438rem, 0.151rem + 1.22vw, 1.25rem);
  color: #666;
  font-size: clamp(0.875rem, 0.8309859154929577rem + 0.18779342723004694vw, 1rem);
  font-weight: 400;
}
</style>
