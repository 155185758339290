import 'normalize.css'

import './assets/index.css'

import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import { initFacebookPixel } from './utils/fb-pixel.ts'

const PIXEL_ID = '575415018849158'

initFacebookPixel(PIXEL_ID)
const app = createApp(App)

app.use(router)

app.mount('#app')
