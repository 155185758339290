<template>
  <div
    :style="{ top: topOffset }"
    :class="status === false ? 'main-right' : 'main-left'"
    @click="closeDrawer"
  >
    <div class="drawer" :style="computedStyle" @click="clickBlank">
      <div class="w-full h-full">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, watch, type HTMLAttributes } from 'vue'

const props = withDefaults(
  defineProps<{
    width?: string
    height?: string
    topOffset?: string
  }>(),
  {
    width: '100vw',
    height: '100vh',
    topOffset: '0',
  },
)
const status = defineModel<boolean>({
  default: false,
})

const clickBlank = (event: Event) => {
  event.stopPropagation()
}
const closeDrawer = (event: Event) => {
  event.stopPropagation()
  status.value = false
}
const computedStyle = computed<HTMLAttributes['style']>(() => {
  const base = {
    width: props.width,
    height: props.height,
  }
  if (!status.value) {
    return {
      ...base,
      position: 'fixed',
      top: props.topOffset,
      right: `calc(-1 * ${props.width})`,
    }
  }
  return {
    ...base,
    position: 'fixed',
    top: props.topOffset,
    right: `calc(-1 * ${props.width})`,
    transform: `translateX(calc(-1 * ${props.width}))`,
  }
})
watch(() => status.value, () => {
  if (status.value) {
    document.body.classList.add('no-scroll')
  } else {
    if (document.body.classList.contains('no-scroll')) {
      document.body.classList.remove('no-scroll')
    }
  }
})
</script>

<style>
.main-right {
  position: fixed;
  top: 0;
  left: 100%;
  z-index: -1;
}
.main-left {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(61, 59, 59, 0.3);
  transition: all 1s;
}
.drawer {
  background-color: white;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999999;
  overflow: hidden;
}
</style>
