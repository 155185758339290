<template>
  <div
    class="w-full"
    style="
      border-radius: 0 0 clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem)
        clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem);
      background-color: #f5efe6;
      padding-top: calc(
        clamp(2.5rem, 0.958rem + 6.58vw, 6.88rem) +
          clamp(1.25rem, 1.03rem + 0.94vw, 1.875rem)
      );
      padding-bottom: clamp(3.13rem, 2.031rem + 4.69vw, 6.25rem);
    "
  >
    <div
      class="m-auto px-4 relative sm:max-w-[540px-to-767px] md:max-w-[720px-to-991px] lg:max-w-[960px-to-1279px] min-xl:max-w-[1232px]"
    >
      <div class="gap flex items-start gap-6 justify-center">
        <div style="z-index: 1">
          <div class="text-container flex flex-col min-lg:pt-12" style="gap: 0.62rem">
            <h2
              class="section-title"
              style="margin-bottom: calc(1.25rem - 0.62rem)"
            >
              Take Your First Step To
              <p>A <span class="dark-green">Healthier You</span> Today</p>
            </h2>
            <div
              v-for="advantage in advantageList"
              :key="advantage"
              class="advantage min-sm:max-w-[32rem]"
              style="font-size: clamp(18px, 15.5138px + 0.662983vw, 24px)"
            >
              <div>
                <CheckedSvg />
              </div>
              <p v-html="advantage"></p>
            </div>
            <button
              style="
                height: 3.125rem;
                font-size: 1.25rem;
                margin-top: calc(clamp(1.25rem, 0.68rem + 2.43vw, 2.87rem) - 0.62rem);
              "
              class="button-gradient mt-1 max-xs:h-12 max-xs:text-base rounded-full tracking-[0.1em] h-14 flex items-center justify-center font-medium text-lg text-nowrap px-4 text-center gap-2 relative bg-secondary text-white w-full max-xs:w-full min-sm:max-w-[32rem]"
              type="button"
              @click="toSku"
            >
              Get Started
            </button>
          </div>
          <p class="description">
            *<b>After 68 weeks</b>, participants administering once-weekly semaglutide treatment
            experienced an average of 15% reduction in body weight. The mean change in body weight
            from baseline to week 68 was −14.9% in the semaglutide group as compared with −2.4% with
            placebo.
            <a target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/33567185/" class="link"
              >(Source)</a
            >
          </p>
        </div>
        <img loading="lazy" draggable="false" :src="ImageSrc" class="image" alt="medication" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import ImageSrc from '@/assets/image/TakeYourFirstStepToAHealthierYouToday.webp'
import CheckedSvg from '@/assets/svg/checked.svg'
import { toSku } from '@/utils'

const advantageList = [
  '<strong style="font-weight: 700;">6x</strong> more weight loss than exercise and diet alone*',
  'Lose an average of <strong style="font-weight: 700;">14.9%</strong> of your body weight*',
  'When combined with diet and exercise, weight management can be within reach',
]
</script>
<style scoped>
button {
  color: #fff;
  font-family: 'San Francisco Display';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.advantage {
  display: flex;
  padding: 10px;
  border-radius: 10px;
  background: #f3f3f3;
  align-items: center;
  gap: 20px;
}
.advantage p {
  width: clamp(13.1875rem, 8.940140845070424rem + 18.12206572769953vw, 26.25rem);
  color: #666;
  font-family: 'San Francisco Display';
  font-size: clamp(1rem, 0.9559859154929577rem + 0.18779342723004694vw, 11.125rem);
  font-weight: 400;
  font-style: normal;
  line-height: 166.667%;
  text-transform: capitalize;
}
.text-container {
  width: clamp(20rem, 13.838028169014084rem + 26.291079812206576vw, 37.5rem);
  padding: clamp(1.875rem, 1.435rem + 1.88vw, 3.125rem) clamp(1.25rem, 0.81rem + 1.88vw, 2.5rem)
  clamp(1.875rem, 0.951rem + 3.94vw, 4.5rem) clamp(1.25rem, 0.59rem + 2.82vw, 3.125rem);
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 4px 20px 0px rgba(26, 77, 46, 0.2);
  margin-top: 10rem;
}
.image {
  border-radius: 24px;
  position: relative;
  margin-top: 0;
  margin-left: -5rem;
  width: 41.25rem;
  height: 52.5rem;
  flex-shrink: 0;
}
.description {
  min-height: 90px;
  margin-top: 2rem;
  margin-left: 2.5rem;
  width: clamp(21.5625rem, 19.031690140845072rem + 10.7981220657277vw, 28.75rem);
  color: #666;
  font-family: 'San Francisco Display';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  text-transform: capitalize;
}
.description a {
  color: #666;
  font-family: 'San Francisco Display';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: 1px;
  text-underline-position: from-font;
  text-transform: capitalize;
}
/* if vw smaller than 1050 */
@media (max-width: 1280px) {
  .text-container {
    margin-top: 22rem;
    margin-right: 0;
    margin-left: 0;
  }
  .image {
    width: clamp(18.75rem, 11.907582938388625rem + 28.436018957345972vw, 37.5rem);
    height: clamp(25.375rem, 14.221860189573459rem + 46.350710900473935vw, 55.9375rem);
    flex-shrink: 0;
    margin-left: -19.5rem;
  }
  .description {
    margin-top: 1rem;
    margin-left: 0;
  }
}
</style>
